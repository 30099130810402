export const HINO_SOS_EXTERNAL_PENDING = 'HINO_SOS_EXTERNAL_PENDING';
export const HINO_SOS_EXTERNAL_SUCCEEDED = 'HINO_SOS_EXTERNAL_SUCCEEDED';
export const HINO_SOS_EXTERNAL_FAILED = 'HINO_SOS_EXTERNAL_FAILED';
export const FETCH_CAR_NUMBER_OTHER_PEDING = 'FETCH_CAR_NUMBER_OTHER_PEDING';
export const FETCH_CAR_NUMBER_OTHER_SUCCEEDED =
  'FETCH_CAR_NUMBER_OTHER_SUCCEEDED';
export const FETCH_CAR_NUMBER_OTHER_FAILED = 'FETCH_CAR_NUMBER_OTHER_FAILED';

export const FETCH_CONTROL_DATA_PENDING = 'FETCH_CONTROL_DATA_PENDING';
export const FETCH_CONTROL_DATA_SUCCEEDED = 'FETCH_CONTROL_DATA_SUCCEEDED';
export const FETCH_CONTROL_DATA_FAILED = 'FETCH_CONTROL_DATA_FAILED';
export const FETCH_HINO_SOS_CONTROL_DATA_PENDING =
  'FETCH_HINO_SOS_CONTROL_DATA_PENDING';
export const FETCH_HINO_SOS_CONTROL_DATA_SUCCEEDED =
  'FETCH_HINO_SOS_CONTROL_DATA_SUCCEEDED';
export const FETCH_HINO_SOS_CONTROL_DATA_FAILED =
  'FETCH_HINO_SOS_CONTROL_DATA_FAILED';
export const FETCH_HINO_SOS_CONTROL_DATA_FROM_DATE_TIME_PENDING =
  'FETCH_HINO_SOS_CONTROL_DATA_FROM_DATE_TIME_PENDING';
export const FETCH_HINO_SOS_CONTROL_DATA_FROM_DATE_TIME_SUCCEEDED =
  'FETCH_HINO_SOS_CONTROL_DATA_FROM_DATE_TIME_SUCCEEDED';
export const FETCH_HINO_SOS_CONTROL_DATA_FROM_DATE_TIME_FAILED =
  'FETCH_HINO_SOS_CONTROL_DATA_FROM_DATE_TIME_FAILED';

export const FETCH_HINO_SOS_PENDING = 'FETCH_HINO_SOS_PENDING';
export const FETCH_HINO_SOS_SUCCEEDED = 'FETCH_HINO_SOS_SUCCEEDED';
export const FETCH_HINO_SOS_FAILED = 'FETCH_HINO_SOS_FAILED';

export const FETCH_SELECTED_HINO_SOS_INQIURY_SUCCEEDED =
  'FETCH_SELECTED_HINO_SOS_INQIURY_SUCCEEDED';
export const UPDATE_HINO_SOS_INQUIRY_PENDING =
  'UPDATE_HINO_SOS_INQUIRY_PENDING';
export const UPDATE_HINO_SOS_INQUIRY_SUCCEEDED =
  'UPDATE_HINO_SOS_INQUIRY_SUCCEEDED';
export const UPDATE_HINO_SOS_INQUIRY_FAILED = 'UPDATE_HINO_SOS_INQUIRY_FAILED';

export const POST_HINO_SOS_PENDING = 'POST_HINO_SOS_PENDING';
export const POST_HINO_SOS_SUCCEEDED = 'POST_HINO_SOS_SUCCEEDED';
export const POST_HINO_SOS_FAIL = 'POST_HINO_SOS_FAIL';

export const UPDATE_SPECIAL_CLIENT_USER_PENDING =
  'UPDATE_SPECIAL_CLIENT_USER_PENDING';
export const UPDATE_SPECIAL_CLIENT_USER_SUCCEEDED =
  'UPDATE_SPECIAL_CLIENT_USER_SUCCEEDED';
export const UPDATE_SPECIAL_CLIENT_USER_FAIL =
  'UPDATE_SPECIAL_CLIENT_USER_FAIL';

export const FETCH_HINO_SOS_SPECIAL_CLIENT_PENDING =
  'FETCH_HINO_SOS_SPECIAL_CLIENT_PENDING';
export const FETCH_HINO_SOS_SPECIAL_CLIENT_SUCCEEDED =
  'FETCH_HINO_SOS_SPECIAL_CLIENT_SUCCEEDED';
export const FETCH_HINO_SOS_SPECIAL_CLIENT_FAIL =
  'FETCH_HINO_SOS_SPECIAL_CLIENT_FAIL';

export const FETCH_HINO_SOS_SPECIAL_CLIENT_CONTROLDATA_PENDING =
  'FETCH_HINO_SOS_SPECIAL_CLIENT_CONTROLDATA_PENDING';
export const FETCH_HINO_SOS_SPECIAL_CLIENT_CONTROLDATA_SUCCEEDED =
  'FETCH_HINO_SOS_SPECIAL_CLIENT_CONTROLDATA_SUCCEEDED';
export const FETCH_HINO_SOS_SPECIAL_CLIENT_CONTROLDATA_FAIL =
  'FETCH_HINO_SOS_SPECIAL_CLIENT_CONTROLDATA_FAIL';

export const POST_SPECIAL_USER_PENDING = 'POST_SPECIAL_USER_PENDING';
export const POST_SPECIAL_USER_SUCCEEDED = 'POST_SPECIAL_USER_SUCCEEDED';
export const POST_SPECIAL_USER_FAIL = 'POST_SPECIAL_USER_FAIL';

export const FETCH_OFFICES_STAFF_STATUS_PENDING =
  'FETCH_OFFICES_STAFF_STATUS_PENDING';
export const FETCH_OFFICES_STAFF_STATUS_SUCCEEDED =
  'FETCH_OFFICES_STAFF_STATUS_SUCCEEDED';
export const FETCH_OFFICES_STAFF_STATUS_FAILED =
  'FETCH_OFFICES_STAFF_STATUS_FAILED';

export const FETCH_CAR_DEALER_PEDING = 'FETCH_CAR_DEALER_PEDING';
export const FETCH_CAR_DEALER_SUCCEEDED = 'FETCH_CAR_DEALER_SUCCEEDED';
export const FETCH_CAR_DEALER_FAILED = 'FETCH_CAR_DEALER_FAILED';

export const FETCH_RECEPTION_PEDING = 'FETCH_RECEPTION_PEDING';
export const FETCH_RECEPTION_SUCCEEDED = 'FETCH_RECEPTION_SUCCEEDED';
export const FETCH_RECEPTION_FAILED = 'FETCH_RECEPTION_FAILED';
export const FETCH_COUNT_RECEPTION_PEDING = 'FETCH_COUNT_RECEPTION_PEDING';
export const FETCH_COUNT_RECEPTION_SUCCEEDED =
  'FETCH_COUNT_RECEPTION_SUCCEEDED';
export const FETCH_COUNT_RECEPTION_FAILED = 'FETCH_COUNT_RECEPTION_FAILED';

export const CREATE_WORK_REPORT_PENDING = 'CREATE_WORK_REPORT_PENDING';
export const CREATE_WORK_REPORT_SUCCEEDED = 'CREATE_WORK_REPORT_SUCCEEDED';
export const CREATE_WORK_REPORT_FAILED = 'CREATE_WORK_REPORT_FAILED';

export const DELETE_HINO_SOS_INQUIRY_PENDING =
  'DELETE_HINO_SOS_INQUIRY_PENDING';
export const DELETE_HINO_SOS_INQUIRY_SUCCEEDED =
  'DELETE_HINO_SOS_INQUIRY_SUCCEEDED';
export const DELETE_HINO_SOS_INQUIRY_FAILED = 'DELETE_HINO_SOS_INQUIRY_FAILED';

export const PENDING_CSV_DOWNLOAD = 'PENDING_CSV_DOWNLOAD';
export const SUCCESS_CSV_DOWNLOAD = 'SUCCESS_CSV_DOWNLOAD';
export const FAIL_CSV_DOWNLOAD = 'FAIL_CSV_DOWNLOAD';

export const REQUEST_SHIFT_TABLE_LOGIN = 'REQUEST_SHIFT_TABLE_LOGIN';
export const REQUEST_SHIFT_TABLE_LOGIN_SUCCEEDED =
  'REQUEST_SHIFT_TABLE_LOGIN_SUCCEEDED';
export const REQUEST_SHIFT_TABLE_LOGIN_FAILED =
  'REQUEST_SHIFT_TABLE_LOGIN_FAILED';

export const FETCH_CAR_DEALER_PDF_PENDING = 'FETCH_CAR_DEALER_PDF_PENDING';
export const FETCH_CAR_DEALER_PDF_SUCCEEDED = 'FETCH_CAR_DEALER_PDF_SUCCEEDED';
export const FETCH_CAR_DEALER_PDF_FAILED = 'FETCH_CAR_DEALER_PDF_FAILED';

export const REQUEST_SHIFT_TABLE_CONTROL_DATA =
  'REQUEST_SHIFT_TABLE_CONTROL_DATA';
export const REQUEST_SHIFT_TABLE_CONTROL_DATA_SUCCEEDED =
  'REQUEST_SHIFT_TABLE_CONTROL_DATA_SUCCEEDED';
export const REQUEST_SHIFT_TABLE_CONTROL_DATA_FAILED =
  'REQUEST_SHIFT_TABLE_CONTROL_DATA_FAILED';

export const REQUEST_CAR_DEALER_BRANCH_DETAILS =
  'REQUEST_CAR_DEALER_BRANCH_DETAILS';
export const REQUEST_CAR_DEALER_BRANCH_DETAILS_SUCCEEDED =
  'REQUEST_CAR_DEALER_BRANCH_DETAILS_SUCCEEDED';
export const REQUEST_CAR_DEALER_BRANCH_DETAILS_FAILED =
  'REQUEST_CAR_DEALER_BRANCH_DETAILS_FAILED';

export const REQUEST_UPDATE_CAR_DEALER_BRANCHES =
  'REQUEST_UPDATE_CAR_DEALER_BRANCHES';
export const REQUEST_UPDATE_CAR_DEALER_BRANCHES_SUCCEEDED =
  'REQUEST_UPDATE_CAR_DEALER_BRANCHES_SUCCEEDED';
export const REQUEST_UPDATE_CAR_DEALER_BRANCHES_FAILED =
  'REQUEST_UPDATE_CAR_DEALER_BRANCHES_FAILED';

export const CANCEL_REQUEST = 'CANCEL_REQUEST';

export const GET_INQUIRY_DETAILS = 'GET_INQUIRY_DETAILS';

export const IS_SPECIAL_USER = 'IS_SPECIAL_USER';

export const HINO_SOS_STATUS_DOWNLOADING = 'HINO_SOS_STATUS_DOWNLOADING';
