import { combineReducers } from 'redux';
import globalState from 'layouts/store/reducer';
import loader from 'modules/loader/store/reducer';
import auth from 'modules/auth/store/reducer';
import callLog from 'modules/callLog/store/reducer';
import notification from 'modules/notification/store/reducer';
import user from 'modules/user/store/reducer';
import izaCall from 'modules/izacall/store/reducer';
import client from 'modules/client/store/reducer';
import hinoConnect from 'modules/hinoconnect/store/reducer';
import hinoSos from 'modules/hinosos/store/reducer';
import towTruck from 'modules/management/store/reducer';
import setting from 'modules/setting/store/reducer';
import justDoIt from 'modules/justDoIt/store/reducer';
import outBound from 'modules/outbound/store/reducer';

const reducers = combineReducers({
  globalState,
  loader,
  notification,
  auth,
  callLog,
  user,
  izaCall,
  client,
  hinoConnect,
  hinoSos,
  towTruck,
  setting,
  justDoIt,
  outBound,
});

export default reducers;
