export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const SELECTED_ROWS = 'SELECTED_ROWS';
export const SELECTED_IDS = 'SELECTED_IDS';
export const SELECTED_VEHICLE_CHECK = 'SELECTED_VEHICLE_CHECK';
export const SIDEBAR_STATUS = 'SIDEBAR_STATUS';
export const VALIDATE_WORK_REPORT_ROW_ONE = 'VALIDATE_WORK_REPORT_ROW_ONE';
export const VALIDATE_WORK_REPORT_ROW_TWO = 'VALIDATE_WORK_REPORT_ROW_TWO';
export const VALIDATE_WORK_REPORT_ROW_THREE = 'VALIDATE_WORK_REPORT_ROW_THREE';
export const OPEN_WORK_REPORT_ROW_ONE = 'OPEN_WORK_REPORT_ROW_ONE';
export const OPEN_WORK_REPORT_ROW_TWO = 'OPEN_WORK_REPORT_ROW_TWO';
export const OPEN_WORK_REPORT_ROW_THREE = 'OPEN_WORK_REPORT_ROW_THREE';
export const SELECT_TAB_WORK_REPORT_ROW_ONE = 'SELECT_TAB_WORK_REPORT_ROW_ONE';
export const SELECT_TAB_WORK_REPORT_ROW_TWO = 'SELECT_TAB_WORK_REPORT_ROW_TWO';
export const SELECT_TAB_WORK_REPORT_ROW_THREE = 'SELECT_TAB_WORK_REPORT_ROW_THREE';
export const GET_VALUE_MODAL_SUPPORT = 'GET_VALUE_MODAL_SUPPORT';