import React, { forwardRef, Fragment } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Collapse, ListItem } from '@material-ui/core';
import * as actions from '../../store/actionCreators';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { urlHinoConnect } from '../../../utils/routes';

const CustomRouterLink = forwardRef((props, ref) => {
  return (
    <div style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  );
});

const NavItem = (props) => {
  const { page, classes, isDesktop } = props;
  const dispatch = useDispatch();

  const isCurrentPage = window.location.pathname.includes(page.href);
  const hasSubMenu = page.subMenu && page.subMenu.length > 0;
  const [openSubMenu, setOpenSubMenu] = React.useState(isCurrentPage);
  let pathName = useLocation().pathname;
  let currentPath = pathName.split('/');
  let path = '/' + currentPath[1] + '/' + currentPath[2];

  const handleClick = (event) => {
    if (!isDesktop) {
      dispatch(actions.closeSideBar());
    }
    if (isCurrentPage && page.href !== '/') {
      event.preventDefault();
      setOpenSubMenu(!openSubMenu);
    }
  };

  const handleSubMenuClick = () => {
    localStorage.setItem('backFormInquiryDetails', false);
    localStorage.setItem('backFormInquiryDetailsHinoConnect', false);
    localStorage.setItem('backFormInquiryDetailsJustDoIt', false);
    localStorage.setItem(
        'backFormInquiryDetailsJustDoItFollowCall',
        false,
    );
    localStorage.setItem(
        'backFormInquiryDetailsJustDoItCallApplication',
        false,
    );
    if (!isDesktop) {
      dispatch(actions.closeSideBar());
    }
  };

  let subList = [];
  if (hasSubMenu) {
    subList = page.subMenu.map((subMenu) => (
      <Collapse
        in={openSubMenu}
        timeout="auto"
        unmountOnExit
        key={subMenu.label}>
        <ListItem className={classes.item} disableGutters>
          <Button
            onClick={handleSubMenuClick}
            activeClassName={classes.active}
            className={clsx(
              classes.button,
              subMenu.path === urlHinoConnect.inquiryForm &&
                path === urlHinoConnect.inquiryDetails &&
                classes.active,
            )}
            component={CustomRouterLink}
            to={subMenu.path}>
            <div className={classes.icon} />
            {subMenu.label}
          </Button>
        </ListItem>
      </Collapse>
    ));
  }

  return (
    <Fragment key={page.title}>
      <ListItem className={classes.item} disableGutters>
        <Button
          onClick={handleClick}
          activeClassName={classes.active}
          className={classes.button}
          component={CustomRouterLink}
          to={page.href}
          exact>
          <div className={classes.icon}>{page.icon}</div>
          {page.title}
        </Button>
      </ListItem>
      {subList}
    </Fragment>
  );
};

NavItem.propTypes = {
  page: PropTypes.object,
  classes: PropTypes.object,
};

export default NavItem;
