export const FETCH_IZA_CALL_PENDING = 'FETCH_IZA_CALL_PENDING';
export const FETCH_IZA_CALL_SUCCEEDED = 'FETCH_IZA_CALL_SUCCEEDED';
export const FETCH_IZA_CALL_FAILED = 'FETCH_IZA_CALL_FAILED';

export const FETCH_IZA_CALL_CONTROL_DATA_PENDING =
  'FETCH_IZA_CALL_CONTROL_DATA_PENDING';
export const FETCH_IZA_CALL_CONTROL_DATA_SUCCEEDED =
  'FETCH_IZA_CALL_CONTROL_DATA_SUCCEEDED';
export const FETCH_IZA_CALL_CONTROL_DATA_FAIL =
  'FETCH_IZA_CALL_CONTROL_DATA_FAIL';

export const POST_IZA_CALL_PENDING = 'POST_IZA_CALL_PENDING';
export const POST_IZA_CALL_SUCCEEDED = 'POST_IZA_CALL_SUCCEEDED';
export const POST_IZA_CALL_FAIL = 'POST_IZA_CALL_FAIL';

export const UPDATE_IZA_CALL_PENDING = 'UPDATE_IZA_CALL_PENDING';
export const UPDATE_IZA_CALL_SUCCEEDED = 'UPDATE_IZA_CALL_SUCCEEDED';
export const UPDATE_IZA_CALL_FAIL = 'UPDATE_IZA_CALL_FAIL';

export const CLEAR_IZA_CALL_FIELDS = 'CLEAR_IZA_CALL_FIELDS';
export const CLEAR_INQUIRY_LIST_ITEMS = 'CLEAR_INQUIRY_LIST_ITEMS';
export const IZA_CALL_INITIAL_STATE = 'IZA_CALL_INITIAL_STATE';
