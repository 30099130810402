import * as types from './actionTypes';

const initialState = {
  fetching: false,
  searching: false,
  creating: false,
  storing: false,
  deleting: false,
  deleted: false,
  failed: false,
  exporting: false,
  error: {},
  items: [],
  meta: {},
  controlData: {},
  towTruckDetails: {},
};

function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.CANCEL_REQUEST:
      return {
        ...state,
        fetching: false,
        failed: true,
      };

    //Search
    case types.FETCH_TOWTRUCK_PENDING:
      return {
        ...state,
        fetching: true,
        searching: true,
        failed: false,
      };

    case types.FETCH_TOWTRUCK_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        searching: false,
        failed: false,
        items: payload.data,
        meta: payload.meta,
      };

    case types.FETCH_TOWTRUCK_FAILED:
      return {
        ...state,
        fetching: false,
        searching: false,
        failed: true,
        error: payload,
      };

    case types.FETCH_SELECTED_TOWTRUCK_COMPANY_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        searching: false,
      };

    //Create
    case types.POST_TOWTRUCK_PENDING:
      return {
        ...state,
        creating: true,
        failed: false,
      };

    case types.POST_TOWTRUCK_SUCCEEDED:
      return {
        ...state,
        creating: false,
        failed: false,
      };

    case types.POST_TOWTRUCK_FAIL:
      return {
        ...state,
        creating: false,
        failed: true,
        error: payload,
      };

    //Update
    case types.UPDATE_TOWTRUCK_DATA_PENDING:
      return {
        ...state,
        creating: true,
        fetching: true,
        failed: false,
      };
    case types.UPDATE_TOWTRUCK_DATA_SUCCEEDED:
      return {
        ...state,
        creating: false,
        fetching: false,
        storing: false,
        failed: false,
        items: state.items.map((value) =>
          value.id === payload.id ? { ...payload } : value,
        ),
      };
    case types.UPDATE_TOWTRUCK_DATA_FAILED:
      return {
        ...state,
        creating: false,
        fetching: false,
        failed: true,
        error: payload,
      };

    //Control data
    case types.FETCH_TOWTRUCK_CONTROL_DATA_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_TOWTRUCK_CONTROL_DATA_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        controlData: {
          ...payload.data,
        },
      };
    case types.FETCH_TOWTRUCK_CONTROL_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    // DELETE
    case types.DELETE_TOWTRUCK_DATA_PENDING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      };
    case types.DELETE_TOWTRUCK_DATA_SUCCEEDED:
      return {
        ...state,
        deleting: false,
        deleted: true,
        carDealer: {
          items: state.carDealer.items.filter(
            (obj) => !payload.includes(obj.id),
          ),
        },
        items: state.items.filter((obj) => !payload.includes(obj.id)),
      };
    case types.DELETE_TOWTRUCK_DATA_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        failed: true,
        error: payload,
      };

    case types.FETCH_SELECTED_TOWTRUCK_DATA_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        searching: false,
      };
    case types.UPDATE_TOWTRUCK_DATA_PENDING:
      return {
        ...state,
        creating: true,
        fetching: true,
        failed: false,
      };
    case types.UPDATE_TOWTRUCK_DATA_SUCCEEDED:
      return {
        ...state,
        creating: false,
        fetching: false,
        storing: false,
        failed: false,
        items: state.items.map((value) =>
          value.id === payload.id ? { ...payload } : value,
        ),
      };
    case types.UPDATE_TOWTRUCK_DATA_FAILED:
      return {
        ...state,
        creating: false,
        fetching: false,
        failed: true,
        error: payload,
      };

    case types.GET_TOWTRUCK_DATA_DETAILS:
      return {
        ...state,
        inquiryDetails: { ...payload },
      };

    case types.PENDING_CSV_DOWNLOAD:
      return {
        ...state,
        exporting: true,
      };

    case types.SUCCESS_CSV_DOWNLOAD:
      return {
        ...state,
        exporting: false,
        error: { ...payload },
      };

    case types.FAIL_CSV_DOWNLOAD:
      return {
        ...state,
        exporting: false,
        error: { ...payload },
      };

    default:
      return state;
  }
}

export default reducer;
