export const FETCH_HINO_CONNECT_CONTROL_DATA_PENDING =
  'FETCH_HINO_CONNECT_CONTROL_DATA_PENDING';
export const FETCH_HINO_CONNECT_CONTROL_DATA_SUCCEEDED =
  'FETCH_HINO_CONNECT_CONTROL_DATA_SUCCEEDED';
export const FETCH_HINO_CONNECT_CONTROL_DATA_FAILED =
  'FETCH_HINO_CONNECT_CONTROL_DATA_FAILED';

export const FETCH_HINO_CONNECT_PENDING = 'FETCH_HINO_CONNECT_PENDING';
export const FETCH_HINO_CONNECT_SUCCEEDED = 'FETCH_HINO_CONNECT_SUCCEEDED';
export const FETCH_HINO_CONNECT_FAILED = 'FETCH_HINO_CONNECT_FAILED';

export const POST_HINO_CONNECT_PENDING = 'POST_HINO_CONNECT_PENDING';
export const POST_HINO_CONNECT_SUCCEEDED = 'POST_HINO_CONNECT_SUCCEEDED';
export const POST_HINO_CONNECT_FAILED = 'POST_HINO_CONNECT_FAILED';

export const FETCH_OFFICE_STAFF_STATUS_PENDING =
  'FETCH_OFFICE_STAFF_STATUS_PENDING';
export const FETCH_OFFICE_STAFF_STATUS_SUCCEEDED =
  'FETCH_OFFICE_STAFF_STATUS_SUCCEEDED';
export const FETCH_OFFICE_STAFF_STATUS_FAILED =
  'FETCH_OFFICE_STAFF_STATUS_FAILED';

export const UPDATE_EMAIL_TEMPLATES_PENDING = 'UPDATE_EMAIL_TEMPLATES_PENDING';
export const UPDATE_EMAIL_TEMPLATES_SUCCEEDED =
  'UPDATE_EMAIL_TEMPLATES_SUCCEEDED';
export const UPDATE_EMAIL_TEMPLATES_FAILED = 'UPDATE_EMAIL_TEMPLATES_FAILED';

export const FETCH_EMAIL_TEMPLATES_PENDING = 'FETCH_EMAIL_TEMPLATES_PENDING';
export const FETCH_EMAIL_TEMPLATES_SUCCEEDED =
  'FETCH_EMAIL_TEMPLATES_SUCCEEDED';
export const FETCH_EMAIL_TEMPLATES_FAILED = 'FETCH_EMAIL_TEMPLATES_FAILED';

export const SET_SELECTED_INQUIRY = 'SET_SELECTED_INQUIRY';
export const POST_IMPORT_FILE_PENDING = 'POST_IMPORT_FILE_PENDING';
export const POST_IMPORT_FILE_SUCCEEDED = 'POST_IMPORT_FILE_SUCCEEDED';
export const POST_IMPORT_FILE_FAIL = 'POST_IMPORT_FILE_FAIL';

export const FETCH_IMPORT_FILE_PENDING = 'FETCH_IMPORT_FILE_PENDING';
export const FETCH_IMPORT_FILE_SUCCEEDED = 'FETCH_IMPORT_FILE_SUCCEEDED';
export const FETCH_IMPORT_FILE_FAILED = 'FETCH_IMPORT_FILE_FAILED';

export const DELETE_IMPORT_FILE = 'DELETE_IMPORT_FILE';
export const DELETE_IMPORT_FILE_SUCCEEDED = 'DELETE_IMPORT_FILE_SUCCEEDED';
export const DELETE_IMPORT_FILE_FAILED = 'DELETE_IMPORT_FILE_FAILED';

export const GET_FILE_DOWNLOAD_FAILED = 'GET_FILE_DOWNLOAD_FAILED';

export const UPDATE_HINO_CONNECT_PENDING = 'UPDATE_HINO_CONNECT_PENDING';
export const UPDATE_HINO_CONNECT_SUCCEEDED = 'UPDATE_HINO_CONNECT_SUCCEEDED';
export const UPDATE_HINO_CONNECT_FAILED = 'UPDATE_HINO_CONNECT_FAILED';

export const CLEAR_HINO_CONNECT_FIELDS = 'CLEAR_HINO_CONNECT_FIELDS';

export const FETCH_EXTERNAL_DATA_PENDING = 'FETCH_EXTERNAL_DATA_PENDING';
export const FETCH_EXTERNAL_DATA_SUCCEEDED = 'FETCH_EXTERNAL_DATA_SUCCEEDED';
export const FETCH_EXTERNAL_DATA_CUSTOMER_SUCCEEDED =
  'FETCH_EXTERNAL_DATA_CUSTOMER_SUCCEEDED';
export const FETCH_EXTERNAL_DATA_FAILED = 'FETCH_EXTERNAL_DATA_FAILED';

export const FETCH_EXTERNAL_HISTORY_DATA_PENDING =
  'FETCH_EXTERNAL_HISTORY_DATA_PENDING';
export const FETCH_EXTERNAL_HISTORY_DATA_SUCCEEDED =
  'FETCH_EXTERNAL_HISTORY_DATA_SUCCEEDED';
export const FETCH_EXTERNAL_HISTORY_DATA_FAILED =
  'FETCH_EXTERNAL_HISTORY_DATA_FAILED';

export const DELETE_HINO_INQUIRY_PENDING = 'DELETE_HINO_INQUIRY_PENDING';
export const DELETE_HINO_INQUIRY_SUCCEEDED = 'DELETE_HINO_INQUIRY_SUCCEEDED';
export const DELETE_HINO_INQUIRY_FAILED = 'DELETE_HINO_INQUIRY_FAILED';

export const FETCH_SELECTED_INQIURY_SUCCEEDED =
  'FETCH_SELECTED_INQIURY_SUCCEEDED';

export const GET_CC_MEMO_PENDING = 'GET_CC_MEMO_PENDING';
export const GET_CC_MEMO_SUCCEEDED = 'GET_CC_MEMO_SUCCEEDED';
export const GET_CC_MEMO_FAILED = 'GET_CC_MEMO_FAILED';

export const GET_INQUIRY_DETAILS = 'GET_INQUIRY_DETAILS';

export const STATUS_BUTTON_NEXT = 'STATUS_BUTTON_NEXT';
export const STATUS_BUTTON_BACK = 'STATUS_BUTTON_BACK';

export const FETCH_HINO_CONNECT_ID_NEXT_BACK_PENDING = 'FETCH_HINO_CONNECT_ID_NEXT_BACK_PENDING';
export const FETCH_HINO_CONNECT_ID_NEXT_BACK_SUCCEEDED = 'FETCH_HINO_CONNECT_ID_NEXT_BACK_SUCCEEDED';
export const FETCH_HINO_CONNECT_ID_NEXT_BACK_FAILED = 'FETCH_HINO_CONNECT_ID_NEXT_BACK_FAILED';
