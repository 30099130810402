import Http from 'utils/Http';
import { showLoader, hideLoader } from 'modules/loader/store/actionCreators';
import {
  requestSignIn,
  signIn,
  failSignIn,
  signOut,
  setUser,
  setUserType,
} from './store/actionCreators';
import {
  izaAccessPermission,
  sosAccessPermission,
  connectAccessPermission,
  outboundCallInternalAccessPermission,
  jusdoitAccessPermission,
  accessCallLogPermission,
  accessManagementClientPermission,
  accessManagementUserPermission,
  accessBillPagePermission,
  userDivisionOperator,
  outboundCallExternalAccessPermission,
} from '../../utils/constants';
import { bool } from 'prop-types';

export function signInUser(credentials) {
  return (dispatch) => {
    dispatch(requestSignIn());

    dispatch(showLoader());

    const config = {
      grant_type: 'password',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    };

    const data = { ...credentials, ...config };

    return Http.post('api/login', data)
      .then((response) => {
        dispatch(signIn(response.data));
      })
      .catch((error) => {
        dispatch(failSignIn(error.response.data));
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };
}

export function signOutUser() {
  return (dispatch) => {
    dispatch(showLoader());

    return Http.get('api/signout')
      .then(() => {
        dispatch(signOut());
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };
}

export function getUser() {
  return (dispatch) => {
    dispatch(getTypeUserExternal());
    return Http.get('/api/profile/me').then((response) => {
      let data = response.data.data;
      if (
        data.account &&
        data.account.division &&
        data.account.division === userDivisionOperator
      ) {
        if (
          (data.permissions.indexOf(sosAccessPermission) !== -1 ||
            data.permissions.indexOf(connectAccessPermission) !== -1) &&
          data.permissions.indexOf(accessManagementClientPermission) === -1
        ) {
          data.permissions.push(accessManagementClientPermission);
        }
      }
      if (
        data.has_outbound_external &&
        data.permissions.indexOf(outboundCallExternalAccessPermission) === -1
      ) {
        data.permissions.push(outboundCallExternalAccessPermission);
        localStorage.setItem('external_account', data.has_outbound_external);
      } else {
        localStorage.setItem('external_account', false);
      }
      dispatch(setUser(data));
    });
  };
}

export function getTypeUserExternal() {
  return (dispatch) => {
    return Http.get(`api/outbound/user-type`)
      .then((response) => {
        dispatch(setUserType(response.data.data.data));
      })
      .catch((error) => {});
  };
}
