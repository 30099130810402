import * as types from './actionTypes';

const initialState = {
  isSidebarOpen: false,
  selectedRows: [],
  selectedIds: [],
  vehicleCheckIds: [],
  firstTime: true,
  sidebarStatus: true,
  validateWorkReportRowOne: false,
  validateWorkReportRowTwo: false,
  validateWorkReportRowThree: false,
  openWorkReportRowOne: false,
  openWorkReportRowTwo: false,
  openWorkReportRowThree: false,
  selectTabWorkReportRowOne: false,
  selectTabWorkReportRowTwo: false,
  selectTabWorkReportRowThree: false,
  valueModalSupport: {},
};

function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.OPEN_SIDEBAR:
      localStorage.setItem('openSidebar', 'true');
      return {
        ...state,
        isSidebarOpen: true,
      };
    case types.CLOSE_SIDEBAR:
      localStorage.setItem('openSidebar', 'false');
      return {
        ...state,
        isSidebarOpen: false,
      };
    case types.SELECTED_ROWS:
      return {
        ...state,
        selectedRows: payload,
      };
    case types.SELECTED_IDS:
      return {
        ...state,
        selectedIds: payload,
      };
    case types.SELECTED_VEHICLE_CHECK:
        return {
          ...state,
          vehicleCheckIds: payload,
        };  
    case types.SIDEBAR_STATUS:
      return {
        ...state,
        sidebarStatus: payload,
      };
    case types.VALIDATE_WORK_REPORT_ROW_ONE:
      return {
        ...state,
        validateWorkReportRowOne: payload,
      };
    case types.VALIDATE_WORK_REPORT_ROW_TWO:
      return {
        ...state,
        validateWorkReportRowTwo: payload,
      };
    case types.VALIDATE_WORK_REPORT_ROW_THREE:
      return {
        ...state,
        validateWorkReportRowThree: payload,
      };
    case types.OPEN_WORK_REPORT_ROW_ONE:
      return {
        ...state,
        openWorkReportRowOne: payload,
      };
    case types.OPEN_WORK_REPORT_ROW_TWO:
      return {
        ...state,
        openWorkReportRowTwo: payload,
      };
    case types.OPEN_WORK_REPORT_ROW_THREE:
      return {
        ...state,
        openWorkReportRowThree: payload,
      };
    case types.SELECT_TAB_WORK_REPORT_ROW_ONE:
      return {
        ...state,
        selectTabWorkReportRowOne: payload,
      };
    case types.SELECT_TAB_WORK_REPORT_ROW_TWO:
      return {
        ...state,
        selectTabWorkReportRowTwo: payload,
      };
    case types.SELECT_TAB_WORK_REPORT_ROW_THREE:
      return {
        ...state,
        selectTabWorkReportRowThree: payload,
      };
    case types.GET_VALUE_MODAL_SUPPORT:
      return {
        ...state,
        valueModalSupport: { ...payload },
      };
    default:
      return state;
  }
}

export default reducer;
