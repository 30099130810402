import * as types from './actionTypes';

const initialState = {
  fetching: false,
  searching: false,
  creating: false,
  storing: false,
  deleting: false,
  deleted: false,
  failed: false,
  error: {},
  carDealer: [],
  carDealerCodeName: [],
  serviceDivision: {},
  roadSubtype: {},
  communication: {},
  masterOption: {},
  masterCategories: [],
  allMasterCategories: [],
  outboundVehicle: {},
  outboundCallRequired: {},
  outgoingCallCustomerCompanyDealer: {},
  outgoingAfterCallCustomerCompanyDealer: {},
  outgoingCallDetail: {},
  outgoingAfterCallDetail: {},
  controlData: {},
  notification: {},
  userType: [],
  countRecords: {
    fetching: false,
    error: {},
    data: {},
  },
  countStatus: {
    data: {},
    error: {},
    fetching: false,
  },
  callAfterService: {
    data: {},
    error: {},
    fetching: false,
  },
  dataDesireImprove: {
    data: {},
    error: {},
    fetching: false,
  },
  serviceSatisfaction: {
    data: {},
    error: {},
    fetching: false,
  },
  dataCountIntentImport: {
    data: {},
    error: {},
    fetching: false,
  },
  dataInformationReportDetail: {
    data: {},
    error: {},
    fetching: false,
  },
  dataCountCallStatus: {
    data: {},
    error: {},
    fetching: false,
  },
  contractStaff: {
    data: {},
    error: {},
    fetching: false,
  },
  loadComponent: {
    message: false,
    actionHistory: false,
    salesCompanyTable:false,
  },
  warehousingStatusReport: {
    data: {},
    error: {},
    fetching: false,
  },
  companyMasterMessage: [],
  countHistoryExternalStatus: {
    data: {},
    error: {},
    fetching: false,
  },
  countAfterExternalStatus: {
    data: {},
    error: {},
    fetching: false,
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_CAR_DEALER_DATA_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.FETCH_CAR_DEALER_DATA_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        loading: false,
        carDealer: payload.data,
      };
    case types.FETCH_CAR_DEALER_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };
    case types.FETCH_MASTER_CATEGORIES_DATA_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.FETCH_MASTER_CATEGORIES_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.FETCH_MASTER_CATEGORIES_DATA_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
        masterCategories: payload.data,
      };

    case types.GET_ALL_MASTER_CATEGORIES_DATA_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.GET_ALL_MASTER_CATEGORIES_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.GET_ALL_MASTER_CATEGORIES_DATA_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
        allMasterCategories: payload.data,
      };

    case types.UPDATE_MASTER_CATEGORY_DATA_PENDING:
      return {
        ...state,
        failed: false,
      };
    case types.UPDATE_MASTER_CATEGORY_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.UPDATE_MASTER_CATEGORY_DATA_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
      };
    case types.FETCH_MASTER_OPTION_DATA_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.FETCH_MASTER_OPTION_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.FETCH_MASTER_OPTION_DATA_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
        masterOption: payload.data,
      };
    case types.CREATE_MASTER_OPTION_DATA_PENDING:
      return {
        ...state,
        failed: false,
      };

    case types.CREATE_MASTER_OPTION_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.CREATE_MASTER_OPTION_DATA_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
      };
    case types.UPDATE_MASTER_OPTION_DATA_PENDING:
      return {
        ...state,
        failed: false,
      };
    case types.UPDATE_MASTER_OPTION_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.UPDATE_MASTER_OPTION_DATA_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
      };
    case types.DELETE_MASTER_OPTION_DATA_PENDING:
      return {
        ...state,
        failed: false,
      };
    case types.DELETE_MASTER_OPTION_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.DELETE_MASTER_OPTION_DATA_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
      };
    case types.IMPORT_CUSTOMER_DATE_PENDING:
      return {
        ...state,
        failed: false,
      };
    case types.IMPORT_CUSTOMER_DATE_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.IMPORT_CUSTOMER_DATE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
      };

    case types.FETCH_DM_SHIPPING_DATA_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.FETCH_DM_SHIPPING_DATA_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        loading: false,
        items: payload.data ? payload.data : [],
      };
    case types.FETCH_DM_SHIPPING_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.UPDATE_DM_SHIPPING_DATA_PENDING:
      return {
        ...state,
        failed: false,
        fetching: true,
      };
    case types.UPDATE_DM_SHIPPING_DATA_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.UPDATE_DM_SHIPPING_DATA_FAILED:
      return {
        ...state,
        loading: false,
        fetching: false,
        error: payload.error,
      };

    case types.OUTBOUND_VEHICLE_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.OUTBOUND_VEHICLE_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.OUTGOING_CALL_CUSTOMER_COMPANY_DEALER_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
        outgoingCallCustomerCompanyDealer: payload,
      };

    case types.OUTGOING_CALL_CUSTOMER_COMPANY_DEALER_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.OUTGOING_CALL_CUSTOMER_COMPANY_DEALER_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.OUTGOING_CALL_DETAIL_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
        outgoingCallDetail: payload,
      };

    case types.OUTGOING_CALL_DETAIL_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.OUTGOING_CALL_DETAIL_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.OUTBOUND_VEHICLE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
        outboundVehicle: payload.data,
      };
    case types.OUTBOUND_CALL_REQUIRED_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.OUTBOUND_CALL_REQUIRED_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.OUTBOUND_CALL_REQUIRED_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
        outboundCallRequired: payload.data,
      };

    case types.UPDATE_OUTBOUND_INFORMATION_PENDING:
      return {
        ...state,
        failed: false,
      };

    case types.UPDATE_OUTBOUND_INFORMATION_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.UPDATE_OUTBOUND_INFORMATION_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
      };

    case types.COUNTER_OUTBOUND_LIST_PENDING:
      return {
        ...state,
        countRecords: {
          ...state.countRecords,
          fetching: true,
        },
      };

    case types.COUNTER_OUTBOUND_LIST_SUCCESSFUL:
      return {
        ...state,
        countRecords: {
          ...state.countRecords,
          fetching: false,
          data: payload,
        },
      };

    case types.COUNTER_OUTBOUND_LIST_FAILED:
      return {
        ...state,
        countRecords: {
          ...state.countRecords,
          fetching: false,
          data: {},
          error: payload,
        },
      };

    case types.COUNTER_OUTBOUND_STATUS_PENDING:
      return {
        ...state,
        countStatus: {
          ...state.countStatus,
          fetching: true,
        },
      };

    case types.COUNTER_OUTBOUND_STATUS_SUCCESS:
      return {
        ...state,
        countStatus: {
          ...state.countStatus,
          data: payload,
          fetching: false,
        },
      };

    case types.COUNTER_OUTBOUND_STATUS_FAILED:
      return {
        ...state,
        countStatus: {
          ...state.countStatus,
          data: {},
          error: payload,
          fetching: false,
        },
      };

    case types.OUTGOING_AFTER_CALL_DETAIL_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
        outgoingAfterCallDetail: payload,
      };

    case types.OUTGOING_AFTER_CALL_DETAIL_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.OUTGOING_AFTER_CALL_DETAIL_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.OUTGOING_AFTER_CALL_HISTORY_DETAIL_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
        outgoingAfterCallDetail: payload,
      };

    case types.OUTGOING_AFTER_CALL_HISTORY_DETAIL_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.OUTGOING_AFTER_CALL_HISTORY_DETAIL_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.OUTGOING_AFTER_CALL_CUSTOMER_COMPANY_DEALER_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
        outgoingAfterCallCustomerCompanyDealer: payload,
      };

    case types.OUTGOING_AFTER_CALL_CUSTOMER_COMPANY_DEALER_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.OUTGOING_AFTER_CALL_CUSTOMER_COMPANY_DEALER_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.FETCH_CODE_NAME_CAR_DEALER_DATA_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.FETCH_CODE_NAME_CAR_DEALER_DATA_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        loading: false,
        carDealerCodeName: payload.data,
      };

    case types.FETCH_CODE_NAME_CAR_DEALER_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.EXPORT_CUSTOMER_DATE_PENDING:
      return {
        ...state,
        failed: false,
      };

    case types.EXPORT_CUSTOMER_DATE_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.EXPORT_CUSTOMER_DATE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
      };
    case types.UPDATE_CALL_AFTER_DATA_PENDING:
      return {
        ...state,
        failed: false,
      };
    case types.UPDATE_AFTER_CALL_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };
    case types.UPDATE_AFTER_CALL_DATA_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
      };

    case types.FETCH_CALL_AFTER_SERVICE_SUCCESS:
      return {
        ...state,
        callAfterService: {
          ...state.callAfterService,
          fetching: false,
          data: payload.data,
        },
      };

    case types.FETCH_CALL_AFTER_SERVICE_FAIL:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.FETCH_CALL_AFTER_SERVICE_PENDING:
      return {
        ...state,
        callAfterService: {
          ...state.callAfterService,
          fetching: true,
        },
      };

    case types.FETCH_OUTBOUND_CONTROL_DATA_SUCCEEDED:
      return {
        ...state,
        controlData: payload.data,
      };

    case types.FETCH_OUTBOUND_CONTROL_DATA_PENDING:
      return {
        ...state,
        failed: false,
      };

    case types.FETCH_OUTBOUND_NOTIFICATION_SUCCEEDED:
      return {
        ...state,
        notification: payload.data,
      };

    case types.FETCH_OUTBOUND_NOTIFICATION_PENDING:
      return {
        ...state,
        failed: false,
      };

    case types.FETCH_OUTBOUND_USER_TYPE_SUCCESS:
      return {
        ...state,
        userType: payload.data,
      };

    case types.FETCH_OUTBOUND_USER_TYPE_PENDING:
      return {
        ...state,
        failed: false,
      };

    case types.FETCH_OUTBOUND_USER_TYPE_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.FETCH_DESIRE_IMPROVE_SERVICE_SUCCESS:
      return {
        ...state,
        dataDesireImprove: {
          ...state.dataDesireImprove,
          data: payload,
          fetching: false,
        },
      };
    case types.FETCH_DESIRE_IMPROVE_SERVICE_FAILED:
      return {
        ...state,
        dataDesireImprove: {
          ...state.dataDesireImprove,
          data: {},
          fetching: false,
        },
      };
    case types.FETCH_DESIRE_IMPROVE_SERVICE_PENDING:
      return {
        ...state,
        dataDesireImprove: {
          ...state.dataDesireImprove,
          fetching: true,
        },
      };

    case types.GET_DATA_COUNT_INTENT_IMPORT_REPORT_TABLE_SUCCEEDED:
      return {
        ...state,
        dataCountIntentImport: {
          ...state.dataCountIntentImport,
          data: payload,
          fetching: false,
        },
      };
    case types.GET_DATA_COUNT_INTENT_IMPORT_REPORT_TABLE_FAILED:
      return {
        ...state,
        dataCountIntentImport: {
          ...state.dataCountIntentImport,
          data: {},
          fetching: false,
        },
      };
    case types.GET_DATA_COUNT_INTENT_IMPORT_REPORT_TABLE_PENDING:
      return {
        ...state,
        dataCountIntentImport: {
          ...state.dataCountIntentImport,
          fetching: true,
        },
      };

    case types.GET_INFORMATION_REPORT_DETAIL_TABLE_SUCCEEDED:
      return {
        ...state,
        dataInformationReportDetail: {
          ...state.dataInformationReportDetail,
          data: payload,
          fetching: false,
        },
      };
    case types.GET_INFORMATION_REPORT_DETAIL_TABLE_FAILED:
      return {
        ...state,
        dataInformationReportDetail: {
          ...state.dataInformationReportDetail,
          data: {},
          fetching: false,
        },
      };
    case types.GET_INFORMATION_REPORT_DETAIL_TABLE_PENDING:
      return {
        ...state,
        dataInformationReportDetail: {
          ...state.dataInformationReportDetail,
          fetching: true,
        },
      };

    case types.GET_DATA_COUNT_CALL_STATUS_REPORT_TABLE_SUCCEEDED:
      return {
        ...state,
        dataCountCallStatus: {
          ...state.dataCountCallStatus,
          data: payload,
          fetching: false,
        },
      };
    case types.GET_DATA_COUNT_CALL_STATUS_REPORT_TABLE_FAILED:
      return {
        ...state,
        dataCountCallStatus: {
          ...state.dataCountCallStatus,
          data: {},
          fetching: false,
        },
      };
    case types.GET_DATA_COUNT_CALL_STATUS_REPORT_TABLE_PENDING:
      return {
        ...state,
        dataCountCallStatus: {
          ...state.dataCountCallStatus,
          fetching: true,
        },
      };

    case types.FETCH_SERVICE_SATISFACTION_SUCCESS:
      return {
        ...state,
        serviceSatisfaction: {
          ...state.serviceSatisfaction,
          fetching: false,
          data: payload.data,
        },
      };

    case types.FETCH_SERVICE_SATISFACTION_FAIL:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.FETCH_SERVICE_SATISFACTION_PENDING:
      return {
        ...state,
        serviceSatisfaction: {
          ...state.serviceSatisfaction,
          fetching: true,
        },
      };

    case types.FETCH_CONTRACT_STAFF_SUCCESS:
      return {
        ...state,
        contractStaff: {
          ...state.contractStaff,
          fetching: false,
          data: payload.data,
        },
      };

    case types.FETCH_CONTRACT_STAFF_FAIL:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.FETCH_CONTRACT_STAFF_PENDING:
      return {
        ...state,
        contractStaff: {
          ...state.contractStaff,
          fetching: true,
        },
      };

    case types.HANDLE_CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        loadComponent: {
          ...state.loadComponent,
          message: !state.loadComponent.message,
          actionHistory: !state.loadComponent.actionHistory,
        },
      };

    case types.HANDLE_RELOAD_SALES_COMPANY_TABLE:
      return {
        ...state,
        loadComponent: {
          ...state.loadComponent,
          salesCompanyTable: payload,
        },
      };

    case types.UPDATE_COMPANY_MASTER_VALIDATE_MESSAGE:
      return {
        ...state,
        companyMasterMessage: payload,

      };

    case types.FETCH_WAREHOUSING_STATUS_REPORT_SUCCESS:
      return {
        ...state,
        warehousingStatusReport: {
          ...state.warehousingStatusReport,
          fetching: false,
          data: payload.data,
        },
      };

    case types.FETCH_WAREHOUSING_STATUS_REPORT_FAIL:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.FETCH_WAREHOUSING_STATUS_REPORT_PENDING:
      return {
        ...state,
        warehousingStatusReport: {
          ...state.warehousingStatusReport,
          fetching: true,
        },
      };

    case types.COUNTER_HISTORY_OUTBOUND_STATUS_EXTERNAL_PENDING:
      return {
        ...state,
        countHistoryExternalStatus: {
          ...state.countHistoryExternalStatus,
          fetching: true,
        },
      };

    case types.COUNTER_HISTORY_OUTBOUND_STATUS_EXTERNAL_SUCCESS:
      return {
        ...state,
        countHistoryExternalStatus: {
          ...state.countHistoryExternalStatus,
          data: payload,
          fetching: false,
        },
      };

    case types.COUNTER_HISTORY_OUTBOUND_STATUS_EXTERNAL_FAILED:
      return {
        ...state,
        countHistoryExternalStatus: {
          ...state.countHistoryExternalStatus,
          data: {},
          error: payload,
          fetching: false,
        },
      };

    case types.UPDATE_OUTBOUND_DEALER_PENDING:
      return {
        ...state,
        failed: false,
      };

    case types.UPDATE_OUTBOUND_DEALER_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    case types.UPDATE_OUTBOUND_DEALER_SUCCEEDED:
      return {
        ...state,
        loading: false,
        fetching: false,
      };

    case types.COUNTER_AFTER_OUTBOUND_STATUS_EXTERNAL_PENDING:
      return {
        ...state,
        countAfterExternalStatus: {
          ...state.countAfterExternalStatus,
          fetching: true,
        },
      };

    case types.COUNTER_AFTER_OUTBOUND_STATUS_EXTERNAL_SUCCESS:
      return {
        ...state,
        countAfterExternalStatus: {
          ...state.countAfterExternalStatus,
          data: payload,
          fetching: false,
        },
      };

    case types.COUNTER_AFTER_OUTBOUND_STATUS_EXTERNAL_FAILED:
      return {
        ...state,
        countAfterExternalStatus: {
          ...state.countAfterExternalStatus,
          data: {},
          error: payload,
          fetching: false,
        },
      };

    default:
      return state;
  }
}

export default reducer;
