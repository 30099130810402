import * as types from './actionTypes';

const initialState = {
  fetching: false,
  deleting: false,
  storing: false,
  fail: false,
  fields: {},
  controlData: {},
  error: {},
  updating: false,
  clear: false,
  items: [],
  meta: {},
  countData: [],
  paginate: 10,
  page: 1,
  total: 0,
  failed: false,
  companyClients: {},
  counts: [],
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_CALLING_LIST_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_CALLING_LIST_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        items: payload.items || [],
        total: payload.total || 0,
        paginate: payload.paginate || 10,
        page: payload.currentPage,
        countData: payload.countData.original.data,
      };
    case types.FETCH_CALLING_LIST_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
        items: [],
        total: 0,
        paginate: 10,
        countData: 0,
        page: 0,
      };
    case types.FETCH_APPLY_CALL_LIST_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_APPLY_CALL_LIST_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        items: payload.items || [],
        total: payload.total || 0,
        paginate: payload.paginate || 10,
        page: payload.currentPage,
        countData: payload.countData.original.data,
      };
    case types.FETCH_APPLY_CALL_LIST_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
        items: [],
        total: 0,
        paginate: 10,
        page: 1,
        countData: 0,
      };

    case types.FETCH_FOLLOW_CALL_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        searching: false,
        failed: false,
        items: payload.items,
        counts: payload.count_data,
        total: payload.total,
        paginate: payload.per_page,
        page: payload.current_page,
      };
    case types.FETCH_FOLLOW_CALL_PENDING:
      return {
        ...state,
        fetching: true,
        searching: true,
        failed: false,
        loadingTable: true,
      };
    case types.FETCH_SELECTED_FOLLOW_CALL_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        searching: false,
        loadingTable: false,
      };
    case types.FETCH_FOLLOW_CALL_FAILED:
      return {
        ...state,
        fetching: false,
        searching: false,
        failed: true,
        error: payload,
      };
    case types.FETCH_FOLLOW_CALL_COUNT_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        searching: false,
        failed: false,
        counts: payload,
        meta: payload.meta,
      };
    default:
      return state;
  }
}

export default reducer;
