import * as types from './actionTypes';

const initialState = {
  fetching: false,
  deleting: false,
  storing: false,
  fail: false,
  fields: {},
  controlData: {},
  error: {},
  updating: false,
  clear: false,
  items: [],
  meta: {},
  failed: false,
  companyClients: {},
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_IZA_CALL_CONTROL_DATA_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_IZA_CALL_CONTROL_DATA_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        controlData: { ...payload },
      };
    case types.FETCH_IZA_CALL_CONTROL_DATA_FAIL:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };
    case types.POST_IZA_CALL_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.POST_IZA_CALL_SUCCEEDED:
      return {
        ...state,
        storing: false,
        failed: false,
        items: [payload, ...state.items],
      };

    case types.POST_IZA_CALL_FAIL:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };
    case types.UPDATE_IZA_CALL_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.UPDATE_IZA_CALL_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        storing: false,
        failed: false,
        items: state.items.map((value) =>
          value.id === payload.id ? { ...payload } : value,
        ),
      };
    case types.UPDATE_IZA_CALL_FAIL:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };
    case types.FETCH_IZA_CALL_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_IZA_CALL_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        items: payload.data,
        meta: payload.meta,
      };
    case types.FETCH_IZA_CALL_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };
    case types.CLEAR_IZA_CALL_FIELDS:
      return {
        ...state,
        fetching: false,
        clear: true,
      };
    case types.CLEAR_INQUIRY_LIST_ITEMS:
      return {
        ...state,
        items: undefined,
      };
    case types.IZA_CALL_INITIAL_STATE:
      return {
        ...state,
        clear: false,
      };
    default:
      return state;
  }
}

export default reducer;
