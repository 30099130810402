import * as types from './actionTypes';

const initialState = {
  fetching: false,
  searching: false,
  creating: false,
  storing: false,
  deleting: false,
  deleted: false,
  failed: false,
  exporting: false,
  controlDataFetching: false,
  downloading: false,
  error: {},
  items: [],
  meta: {},
  serviceDivision: [],
  officeStaffStatus: {},
  roadSubtype: [],
  communication: [],
  controlData: {},
  carDealer: {
    items: [],
    meta: {},
  },
  specialClients: {
    controlData: {},
    items: [],
    meta: {},
  },
  externalData: {
    items: [],
    extraItems: [],
    total: 0,
    page: 1,
  },
  shiftTableLoggedIn: false,
  shiftTableLogInFailed: false,
  carDealerPdf: [],
  inquiryDetails: {},
  countReception: {
    item: {},
    loading: false,
  },
  isSpecialUser: false,
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const hour = Array(24)
    .fill(0)
    .map((item, index) => {
      if (index < 10) {
        return `0${index}`;
      } else {
        return index.toString();
      }
    });

  const minute = Array(60)
    .fill(0)
    .map((item, index) => {
      if (index < 10) {
        return `0${index}`;
      } else {
        return index.toString();
      }
    });
  switch (type) {
    case types.CANCEL_REQUEST:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    case types.HINO_SOS_EXTERNAL_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.HINO_SOS_EXTERNAL_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        externalData: {
          ...state.externalData,
          items: payload.data,
          extraItems: (payload.extra && payload.extra) || [],
          extra: payload.rowExtra,
          total: payload.total,
          page: payload.page,
        },
      };

    case types.HINO_SOS_EXTERNAL_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: {
          message: (payload && payload.message) || 'Server Error',
        },
        externalData: {
          ...state.externalData,
          items: [],
          extraItems: [],
          extra: [],
          total: 0,
          page: 0,
        },
      };

    case types.FETCH_HINO_SOS_PENDING:
      return {
        ...state,
        fetching: true,
        searching: true,
        failed: false,
      };

    case types.FETCH_HINO_SOS_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        searching: false,
        failed: false,
        items: payload.data,
        meta: payload.meta,
      };

    case types.FETCH_HINO_SOS_FAILED:
      return {
        ...state,
        fetching: false,
        searching: false,
        failed: true,
        error: payload,
      };
    case types.POST_HINO_SOS_PENDING:
      return {
        ...state,
        creating: true,
        failed: false,
      };

    case types.POST_HINO_SOS_SUCCEEDED:
      return {
        ...state,
        creating: false,
        failed: false,
      };

    case types.POST_HINO_SOS_FAIL:
      return {
        ...state,
        creating: false,
        failed: true,
        error: payload,
      };

    //Update Special Client User
    case types.UPDATE_SPECIAL_CLIENT_USER_PENDING:
      return {
        ...state,
        updating: true,
        failed: false,
      };

    case types.UPDATE_SPECIAL_CLIENT_USER_SUCCEEDED:
      return {
        ...state,
        updating: false,
        failed: false,
        specialClients: {
          ...state.specialClients,
          items: state.specialClients.items.map((value) =>
            value.id === payload.id ? { ...payload } : value,
          ),
        },
      };

    case types.UPDATE_SPECIAL_CLIENT_USER_FAIL:
      return {
        ...state,
        updating: false,
        error: payload,
      };

    //special client
    case types.FETCH_HINO_SOS_SPECIAL_CLIENT_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.FETCH_HINO_SOS_SPECIAL_CLIENT_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        specialClients: {
          ...state.specialClients,
          items: payload.data,
          meta: payload.meta,
        },
      };

    case types.FETCH_HINO_SOS_SPECIAL_CLIENT_FAIL:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };

    //special client control data
    case types.FETCH_HINO_SOS_SPECIAL_CLIENT_CONTROLDATA_PENDING:
      return {
        ...state,
        controlDataFetching: true,
        failed: false,
      };

    case types.FETCH_HINO_SOS_SPECIAL_CLIENT_CONTROLDATA_SUCCEEDED:
      return {
        ...state,
        controlDataFetching: false,
        failed: false,
        specialClients: {
          ...state.specialClients,
          controlData: payload,
        },
      };

    case types.FETCH_HINO_SOS_SPECIAL_CLIENT_CONTROLDATA_FAIL:
      return {
        ...state,
        controlDataFetching: false,
      };

    case types.POST_SPECIAL_USER_PENDING:
      return {
        ...state,
        creating: true,
        failed: false,
      };

    case types.POST_SPECIAL_USER_SUCCEEDED:
      return {
        ...state,
        creating: false,
        failed: false,
        specialClients: {
          ...state.specialClients,
          items: [payload.data, ...state.specialClients.items],
        },
      };

    case types.POST_SPECIAL_USER_FAIL:
      return {
        ...state,
        creating: false,
        failed: true,
        error: payload,
      };

    case types.FETCH_HINO_SOS_CONTROL_DATA_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.FETCH_HINO_SOS_CONTROL_DATA_FROM_DATE_TIME_PENDING:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_HINO_SOS_CONTROL_DATA_FROM_DATE_TIME_SUCCEEDED:
      return {
        ...state,
        loading: false,
        controlData: {
          ...state.controlData,
          ...payload.data,
        },
        officeStaffStatus: {
          ...payload.data,
        },
      };

    case types.FETCH_HINO_SOS_CONTROL_DATA_FROM_DATE_TIME_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        loading: false,
        error: payload.error,
      };

    case types.FETCH_HINO_SOS_CONTROL_DATA_SUCCEEDED:
      const serviceDivision = payload.data.service_division.map(
        (division) => division.name,
      );
      const roadSubType = payload.data.road_sub_type.map(
        (subtype) => subtype.name,
      );
      return {
        ...state,
        fetching: false,
        failed: false,
        loading: false,
        controlData: {
          ...payload.data,
          hour: ['', ...hour],
          minute: ['', ...minute],
        },
        serviceDivision: serviceDivision,
        roadSubtype: roadSubType,
        communication: payload.data.communication,
      };
    case types.FETCH_HINO_SOS_CONTROL_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };

    //Office Staff Status
    case types.FETCH_OFFICES_STAFF_STATUS_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_OFFICES_STAFF_STATUS_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        officeStaffStatus: payload,
      };
    case types.FETCH_OFFICES_STAFF_STATUS_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };
    case types.FETCH_CAR_DEALER_PEDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_RECEPTION_PEDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_CAR_DEALER_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        carDealer: {
          items: payload.data,
          meta: payload.meta,
        },
      };
    case types.FETCH_RECEPTION_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        carDealer: {
          items: payload.data,
          meta: payload.meta,
        },
      };
    case types.FETCH_COUNT_RECEPTION_PEDING:
      return {
        ...state,
        fetching: false,
        failed: false,
        countReception: {
          item: {},
          loading: true,
        },
      };
    case types.FETCH_COUNT_RECEPTION_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        countReception: {
          item: payload,
          loading: false,
        },
      };
    case types.FETCH_COUNT_RECEPTION_FAILED:
      return {
        ...state,
        fetching: false,
        countReception: {
          item: {},
          loading: false,
        },
      };
    case types.FETCH_CAR_DEALER_FAILED:
      return {
        ...state,
        fetching: false,
      };

    case types.FETCH_RECEPTION_FAILED:
      return {
        ...state,
        fetching: false,
      };

    //Office Staff Status
    case types.CREATE_WORK_REPORT_PENDING:
      return {
        ...state,
        creating: true,
        failed: false,
      };
    case types.CREATE_WORK_REPORT_SUCCEEDED:
      return {
        ...state,
        creating: false,
        failed: false,
        items: state.items.map((value) =>
          value.id === payload.id ? { ...payload } : value,
        ),
      };
    case types.CREATE_WORK_REPORT_FAILED:
      return {
        ...state,
        creating: false,
        failed: true,
        error: payload,
      };

    // DELETE INQUIRY
    case types.DELETE_HINO_SOS_INQUIRY_PENDING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      };
    case types.DELETE_HINO_SOS_INQUIRY_SUCCEEDED:
      return {
        ...state,
        deleting: false,
        deleted: true,
        carDealer: {
          items: state.carDealer.items.filter(
            (obj) => !payload.includes(obj.id),
          ),
        },
        items: state.items.filter((obj) => !payload.includes(obj.id)),
      };
    case types.DELETE_HINO_SOS_INQUIRY_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        failed: true,
        error: payload,
      };

    case types.FETCH_SELECTED_HINO_SOS_INQIURY_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        searching: false,
      };
    case types.UPDATE_HINO_SOS_INQUIRY_PENDING:
      return {
        ...state,
        creating: true,
        fetching: true,
        failed: false,
      };
    case types.UPDATE_HINO_SOS_INQUIRY_SUCCEEDED:
      return {
        ...state,
        creating: false,
        fetching: false,
        storing: false,
        failed: false,
        items: state.items.map((value) =>
          value.id === payload.id ? { ...payload } : value,
        ),
      };
    case types.UPDATE_HINO_SOS_INQUIRY_FAILED:
      return {
        ...state,
        creating: false,
        fetching: false,
        failed: true,
        error: payload,
      };

    case types.PENDING_CSV_DOWNLOAD:
      return {
        ...state,
        exporting: true,
      };

    case types.SUCCESS_CSV_DOWNLOAD:
      return {
        ...state,
        exporting: false,
        error: { ...payload },
      };

    case types.FAIL_CSV_DOWNLOAD:
      return {
        ...state,
        exporting: false,
        error: { ...payload },
      };

    // SHIFT_TABLE
    case types.REQUEST_SHIFT_TABLE_LOGIN:
      return {
        ...state,
        fetching: true,
        failed: false,
        shiftTableLoggedIn: false,
        shiftTableLogInFailed: false,
      };
    case types.REQUEST_SHIFT_TABLE_LOGIN_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        shiftTableLoggedIn: payload.authorized,
        shiftTableLogInFailed: !payload.authorized,
        error: payload.error,
      };
    case types.REQUEST_SHIFT_TABLE_LOGIN_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        shiftTableLoggedIn: false,
        shiftTableLogInFailed: true,
        error: payload,
      };
    case types.REQUEST_SHIFT_TABLE_CONTROL_DATA:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_CAR_DEALER_PDF_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_CAR_DEALER_PDF_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        carDealerPdf: payload,
      };
    case types.FETCH_CAR_DEALER_PDF_FAILED:
      return {
        ...state,
        fetching: false,
        failed: false,
        controlData: payload.data,
      };
    case types.REQUEST_SHIFT_TABLE_CONTROL_DATA_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        controlData: { ...payload.data, hour, minute },
      };
    case types.REQUEST_SHIFT_TABLE_CONTROL_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };
    case types.REQUEST_CAR_DEALER_BRANCH_DETAILS:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.REQUEST_CAR_DEALER_BRANCH_DETAILS_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        items: [payload.data],
      };
    case types.REQUEST_CAR_DEALER_BRANCH_DETAILS_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };
    case types.REQUEST_UPDATE_CAR_DEALER_BRANCHES:
      return {
        ...state,
        storing: true,
        failed: false,
      };
    case types.REQUEST_UPDATE_CAR_DEALER_BRANCHES_SUCCEEDED:
      return {
        ...state,
        storing: false,
        failed: false,
        items: [payload.data],
      };
    case types.REQUEST_UPDATE_CAR_DEALER_BRANCHES_FAILED:
      return {
        ...state,
        storing: false,
        failed: true,
        error: payload.error,
      };
    case types.GET_INQUIRY_DETAILS:
      return {
        ...state,
        inquiryDetails: { ...payload },
      };
    case types.IS_SPECIAL_USER:
      return {
        ...state,
        isSpecialUser: payload,
      };
    case types.HINO_SOS_STATUS_DOWNLOADING:
      return {
        ...state,
        downloading: payload,
      };
    default:
      return state;
  }
}

export default reducer;
