export const UPDATE_HITOSS_URL_PENDING = 'UPDATE_HITOSS_URL_PENDING';
export const UPDATE_HITOSS_URL_SUCCEEDED = 'UPDATE_HITOSS_URL_SUCCEEDED';
export const UPDATE_HITOSS_URL_FAILED = 'UPDATE_HITOSS_URL_FAILED';

export const HITOSS_URL_FETCHING = 'HITOSS_URL_FETCHING';
export const HITOSS_URL_FETCHED = 'HITOSS_URL_FETCHED';

export const UPDATE_EXPRESSWAY_PENDING = 'UPDATE_EXPRESSWAY_PENDING';
export const UPDATE_EXPRESSWAY_SUCCEEDED = 'UPDATE_EXPRESSWAY_SUCCEEDED';
export const UPDATE_EXPRESSWAY_FAILED = 'UPDATE_EXPRESSWAY_FAILED';
