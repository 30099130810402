import * as types from './actionTypes';

const initialState = {
  fetching: false,
  storing: false,
  items: {},
  meta: {},
  failed: false,
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_CLIENT_LIST_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_CLIENT_LIST_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        items: payload.data,
        meta: payload.meta,
      };
    case types.FETCH_CLIENT_LIST_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };
    default:
      return state;
  }
}

export default reducer;
