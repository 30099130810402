import * as types from './actionTypes';

const initialState = {
  fetching: false,
  deleting: false,
  storing: false,
  updating: false,
  accounts: [],
  controlData: {
    states: [],
    divisions: [],
    roles: [],
    bases: [],
    permissions: [],
    hasOutbound: ''
  },
  items: [],
  meta: {},
  failed: false,
  error: {},
};

function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_USERS_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_USERS_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        items: payload.data,
        meta: payload.meta,
      };
    case types.FETCH_USERS_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };
    case types.DELETE_USERS_PENDING:
      return {
        ...state,
        deleting: true,
        failed: false,
      };
    case types.DELETE_USERS_SUCCEEDED:
      return {
        ...state,
        deleting: false,
        failed: false,
        items: state.items.filter((obj) => !payload.includes(obj.id)),
      };
    case types.DELETE_USERS_FAILED:
      return {
        ...state,
        deleting: false,
        failed: true,
        error: payload,
      };
    case types.FETCH_USER_CONTROL_DATA_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_USER_CONTROL_DATA_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        controlData: {
          ...state.controlData,
          divisions: payload.account.divisions,
          roles: payload.user.roles,
          bases: payload.account.bases,
          permissions: payload.user.assignable_permissions,
          states: payload.user.states,
        },
      };
    case types.FETCH_USER_CONTROL_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };
    case types.UPDATE_USER_PENDING:
      return {
        ...state,
        updating: true,
        failed: false,
      };
    case types.UPDATE_USER_SUCCEEDED: {
      return {
        ...state,
        updating: false,
        failed: false,
        items: state.items.map((value) =>
          value.id === payload.id ? { ...payload } : value,
        ),
      };
    }
    case types.UPDATE_USER_FAILED:
      return {
        ...state,
        failed: true,
        error: payload,
      };
    case types.POST_USER_PENDING:
      return {
        ...state,
        storing: true,
        failed: false,
      };
    case types.POST_USER_SUCCEEDED:
      return {
        ...state,
        storing: false,
        failed: false,
        items: [payload, ...state.items],
      };
    case types.POST_USER_FAILED:
      return {
        ...state,
        failed: true,
        error: payload,
      };
    case types.CLEAR_USER_ERRORS:
      return {
        ...state,
        error: {},
      };
    case types.FAIL_CSV_DOWNLOAD:
      return {
        ...state,
        error: { ...payload },
      };
    default:
      return state;
  }
}

export default reducer;
