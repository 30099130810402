export const CREATE_CALL_LOGS_PENDING = 'CREATE_CALL_LOGS_PENDING';
export const CREATE_CALL_LOGS_SUCCEEDED = 'CREATE_CALL_LOGS_SUCCEEDED';
export const CREATE_CALL_LOGS_FAILED = 'CREATE_CALL_LOGS_FAILED';

export const FETCH_CALL_LOGS_PENDING = 'FETCH_CALL_LOGS_PENDING';
export const FETCH_CALL_LOGS_SUCCEEDED = 'FETCH_CALL_LOGS_SUCCEEDED';
export const FETCH_CALL_LOGS_FAILED = 'FETCH_CALL_LOGS_FAILED';

export const DELETE_CALL_LOGS_PENDING = 'DELETE_CALL_LOGS_PENDING';
export const DELETE_CALL_LOGS_SUCCEEDED = 'DELETE_CALL_LOGS_SUCCEEDED';
export const DELETE_CALL_LOGS_FAILED = 'DELETE_CALL_LOGS_FAILED';

export const UPDATE_CALL_LOGS_PENDING = 'UPDATE_CALL_LOGS_PENDING';
export const UPDATE_CALL_LOGS_SUCCEEDED = 'UPDATE_CALL_LOGS_SUCCEEDED';
export const UPDATE_CALL_LOGS_FAILED = 'UPDATE_CALL_LOGS_FAILED';

export const FETCH_CALL_LOG_CONTROL_DATA_PENDING =
  'FETCH_CALL_LOGS_CONTROL_DATA_PENDING';
export const FETCH_CALL_LOG_CONTROL_DATA_SUCCEEDED =
  'FETCH_CALL_LOGS_CONTROL_DATA_SUCCEEDED';
export const FETCH_CALL_LOG_CONTROL_DATA_FAILED =
  'FETCH_CALL_LOGS_CONTROL_DATA_FAILED';

export const FETCH_ACCOUNTS_PENDING = 'FETCH_ACCOUNTS_PENDING';
export const FETCH_ACCOUNTS_SUCCEEDED = 'FETCH_ACCOUNTS_SUCCEEDED';
export const FETCH_ACCOUNTS_FAILED = 'FETCH_ACCOUNTS_FAILED';

export const GET_CSV_DOWNLOAD_FAILED = 'GET_CSV_DOWNLOAD_FAILED';
