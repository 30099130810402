import { createStore, applyMiddleware } from 'redux';
import thunkMiddlware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers';

const middlewares = [thunkMiddlware];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);
const initialState = {
  callLog: {},
  user: {},
  izaCall: {},
  client: {},
};
const store = createStore(reducers, initialState, composedEnhancers);

export default store;
