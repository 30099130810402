import * as types from './actionTypes';

const initialState = {
  fetching: false,
  deleting: false,
  updating: false,
  succeed: false,
  items: [],
  accounts: [],
  meta: {},
  failed: false,
  error: {},
};

function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.CREATE_CALL_LOGS_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.CREATE_CALL_LOGS_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
      };
    case types.CREATE_CALL_LOGS_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };
    case types.FETCH_CALL_LOGS_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_CALL_LOGS_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        items: payload.data,
        meta: payload.meta,
      };
    case types.FETCH_CALL_LOGS_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };
    case types.DELETE_CALL_LOGS_PENDING:
      return {
        ...state,
        deleting: true,
        failed: false,
      };
    case types.DELETE_CALL_LOGS_SUCCEEDED:
      return {
        ...state,
        deleting: false,
        failed: false,
        items: state.items.filter((obj) => !payload.includes(obj.id)),
      };
    case types.DELETE_CALL_LOGS_FAILED:
      return {
        ...state,
        deleting: false,
        failed: true,
        error: payload,
      };
    case types.UPDATE_CALL_LOGS_PENDING:
      return {
        ...state,
        updating: true,
        failed: false,
      };
    case types.UPDATE_CALL_LOGS_SUCCEEDED: {
      return {
        ...state,
        updating: false,
        failed: false,
        items: state.items.map((value) =>
          value.id === payload.id ? { ...payload } : value,
        ),
      };
    }
    case types.UPDATE_CALL_LOGS_FAILED:
      return {
        ...state,
        failed: true,
        error: payload,
      };
    case types.FETCH_CALL_LOG_CONTROL_DATA_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_CALL_LOG_CONTROL_DATA_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        ...payload,
      };
    case types.FETCH_CALL_LOG_CONTROL_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.error,
      };
    case types.FETCH_ACCOUNTS_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_ACCOUNTS_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        accounts: payload,
      };
    case types.FETCH_ACCOUNTS_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };
    default:
      return state;
  }
}

export default reducer;
