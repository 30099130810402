import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import Default from 'layouts/Default';
import Loader from 'modules/loader/components/Loader';

function Private(props) {
  const { component, layout, pagePermission, ...rest } = props;
  const auth = useSelector((state) => state.auth);

  const dynamicRedirect = (url) => {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: url,
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  };

  const isUserPermitted = (user) => {
    return user.permissions.some(
      (permission) => pagePermission.indexOf(permission) !== -1,
    );
  };

  if (!auth.isAuthenticated) {
    return dynamicRedirect('/sign-in');
  }

  if (pagePermission && auth.user && !isUserPermitted(auth.user)) {
    return dynamicRedirect('/unauthorized');
  }

  if (props.redirect) {
    return dynamicRedirect(props.redirect);
  }

  const Layout = layout ? lazy(() => import(`../layouts/${layout}`)) : Default;
  const Component = lazy(() => import(`../${component}`));
  const renderLoader = Loader;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={renderLoader()}>
          <Layout>
            <Component {...props} />
          </Layout>
        </Suspense>
      )}
    />
  );
}

export default Private;
