import React from 'react';
import { colors, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  iconCar: {
    width: '40%',
  },
  middlePageContent: {
    marginTop: '8%',
  },
  middleContent: {
    textAlign: 'center',
    marginTop: 20,
  },
}));

export default function NotFound() {
  const classes = useStyles();

  return (
    <Grid className={classes.middlePageContent} container item md={12}>
      <Grid container item md={12}>
        <Grid item md={4}></Grid>
        <Grid className={classes.middleContent} item md={4}>
          <img
            src="/images/Icon_jbaton.jpg"
            alt="image"
            className={classes.iconCar}
          />
        </Grid>
      </Grid>
      <Grid container item md={12}>
        <Grid item md={4}></Grid>
        <Grid className={classes.middleContent} item md={4}>
          <h1>404 Page Not Found!</h1>
        </Grid>
      </Grid>
      <Grid container item md={12}>
        <Grid item md={4}></Grid>
        <Grid className={classes.middleContent} item md={4}>
          <h5>Click <a href="/">here</a> to back the home page</h5>
        </Grid>
      </Grid>
    </Grid>
  );
}
