import * as types from './actionTypes';

export function openSideBar() {
  return {
    type: types.OPEN_SIDEBAR,
  };
}

export function closeSideBar() {
  return {
    type: types.CLOSE_SIDEBAR,
  };
}

export function setSelectedRow(payload) {
  return {
    type: types.SELECTED_ROWS,
    payload: payload,
  };
}

export function selectedId(payload) {
  return {
    type: types.SELECTED_IDS,
    payload: payload,
  };
}

export function vehicleCheckId(payload) {
  return {
    type: types.SELECTED_VEHICLE_CHECK,
    payload: payload,
  };
}

export function setSidebarStatus(payload) {
  return {
    type: types.SIDEBAR_STATUS,
    payload: payload,
  };
}

export function validateWorkReportRowOne(payload) {
  return {
    type: types.VALIDATE_WORK_REPORT_ROW_ONE,
    payload: payload,
  };
}

export function validateWorkReportRowTwo(payload) {
  return {
    type: types.VALIDATE_WORK_REPORT_ROW_TWO,
    payload: payload,
  };
}

export function validateWorkReportRowThree(payload) {
  return {
    type: types.VALIDATE_WORK_REPORT_ROW_THREE,
    payload: payload,
  };
}

export function openWorkReportRowOne(payload) {
  return {
    type: types.OPEN_WORK_REPORT_ROW_ONE,
    payload: payload,
  };
}

export function openWorkReportRowTwo(payload) {
  return {
    type: types.OPEN_WORK_REPORT_ROW_TWO,
    payload: payload,
  };
}

export function openWorkReportRowThree(payload) {
  return {
    type: types.OPEN_WORK_REPORT_ROW_THREE,
    payload: payload,
  };
}

export function selectTabWorkReportRowOne(payload) {
  return {
    type: types.SELECT_TAB_WORK_REPORT_ROW_ONE,
    payload: payload,
  };
}

export function selectTabWorkReportRowTwo(payload) {
  return {
    type: types.SELECT_TAB_WORK_REPORT_ROW_TWO,
    payload: payload,
  };
}

export function selectTabWorkReportRowThree(payload) {
  return {
    type: types.SELECT_TAB_WORK_REPORT_ROW_THREE,
    payload: payload,
  };
}

export function getValueModalSupport(payload) {
  return {
    type: types.GET_VALUE_MODAL_SUPPORT,
    payload: payload,
  };
}
