export const FETCH_TOWTRUCK_CONTROL_DATA_PENDING =
  'FETCH_TOWTRUCK_CONTROL_DATA_PENDING';
export const FETCH_TOWTRUCK_CONTROL_DATA_SUCCEEDED =
  'FETCH_TOWTRUCK_CONTROL_DATA_SUCCEEDED';
export const FETCH_TOWTRUCK_CONTROL_DATA_FAILED =
  'FETCH_TOWTRUCK_CONTROL_DATA_FAILED';

export const FETCH_TOWTRUCK_DATA_PENDING = 'FETCH_TOWTRUCK_DATA_PENDING';
export const FETCH_TOWTRUCK_DATA_SUCCEEDED = 'FETCH_TOWTRUCK_DATA_SUCCEEDED';
export const FETCH_TOWTRUCK_DATA_FAILED = 'FETCH_TOWTRUCK_DATA_FAILED';

export const FETCH_SELECTED_TOWTRUCK_COMPANY_SUCCEEDED =
  'FETCH_SELECTED_TOWTRUCK_COMPANY_SUCCEEDED';

export const FETCH_TOWTRUCK_PENDING = 'FETCH_TOWTRUCK_PENDING';
export const FETCH_TOWTRUCK_SUCCEEDED = 'FETCH_TOWTRUCK_SUCCEEDED';
export const FETCH_TOWTRUCK_FAILED = 'FETCH_TOWTRUCK_FAILED';

export const FETCH_SELECTED_TOWTRUCK_DATA_SUCCEEDED =
  'FETCH_SELECTED_TOWTRUCK_DATA_SUCCEEDED';
export const UPDATE_TOWTRUCK_DATA_PENDING = 'UPDATE_TOWTRUCK_DATA_PENDING';
export const UPDATE_TOWTRUCK_DATA_SUCCEEDED = 'UPDATE_TOWTRUCK_DATA_SUCCEEDED';
export const UPDATE_TOWTRUCK_DATA_FAILED = 'UPDATE_TOWTRUCK_DATA_FAILED';

export const POST_TOWTRUCK_PENDING = 'POST_TOWTRUCK_PENDING';
export const POST_TOWTRUCK_SUCCEEDED = 'POST_TOWTRUCK_SUCCEEDED';
export const POST_TOWTRUCK_FAIL = 'POST_TOWTRUCK_FAIL';

export const DELETE_TOWTRUCK_DATA_PENDING = 'DELETE_TOWTRUCK_DATA_PENDING';
export const DELETE_TOWTRUCK_DATA_SUCCEEDED = 'DELETE_TOWTRUCK_DATA_SUCCEEDED';
export const DELETE_TOWTRUCK_DATA_FAILED = 'DELETE_TOWTRUCK_DATA_FAILED';

export const CANCEL_REQUEST = 'CANCEL_REQUEST';

export const GET_TOWTRUCK_DATA_DETAILS = 'GET_TOWTRUCK_DATA_DETAILS';

export const PENDING_CSV_DOWNLOAD = 'PENDING_CSV_DOWNLOAD';
export const SUCCESS_CSV_DOWNLOAD = 'SUCCESS_CSV_DOWNLOAD';
export const FAIL_CSV_DOWNLOAD = 'FAIL_CSV_DOWNLOAD';
