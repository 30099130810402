export const FETCH_CALLING_LIST_PENDING = 'FETCH_CALLING_LIST_PENDING';
export const FETCH_CALLING_LIST_SUCCEEDED = 'FETCH_CALLING_LIST_SUCCEEDED';
export const FETCH_CALLING_LIST_FAILED = 'FETCH_CALLING_LIST_FAILED';

export const FETCH_APPLY_CALL_LIST_PENDING = 'FETCH_APPLY_CALL_LIST_PENDING';
export const FETCH_APPLY_CALL_LIST_SUCCEEDED =
  'FETCH_APPLY_CALL_LIST_SUCCEEDED';
export const FETCH_APPLY_CALL_LIST_FAILED = 'FETCH_APPLY_CALL_LIST_FAILED';
export const FETCH_FOLLOW_CALL_SUCCEEDED = 'FETCH_FOLLOW_CALL_SUCCEEDED';
export const FETCH_FOLLOW_CALL_PENDING = 'FETCH_FOLLOW_CALL_PENDING';
export const FETCH_SELECTED_FOLLOW_CALL_SUCCEEDED =
  'FETCH_SELECTED_FOLLOW_CALL_SUCCEEDED';
export const FETCH_FOLLOW_CALL_FAILED = 'FETCH_FOLLOW_CALL_FAILED';
export const FETCH_FOLLOW_CALL_COUNT_SUCCEEDED =
  'FETCH_FOLLOW_CALL_COUNT_SUCCEEDED';
