import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import 'typeface-roboto';

import Router from './router/Router';
import theme from './theme';
import './assets/scss/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  urlHinoSOS,
  urlHinoConnect,
  urlJustDoIt,
  urlOutBoundCall,
} from './utils/routes';
import { checkIsSet } from './utils/helpers';

function App() {
  localStorage.setItem('backFormInquiryDetails', false);
  localStorage.setItem('backFormInquiryDetailsHinoConnect', false);
  localStorage.setItem('isBackBeforeCallExternal', false);
  localStorage.setItem('backFormInquiryDetailsJustDoIt', false);
  localStorage.setItem('backFormInquiryDetailsJustDoItFollowCall', false);
  localStorage.setItem('backFormInquiryDetailsJustDoItCallApplication', false);
  window.onpopstate = function () {
    localStorage.setItem('backFormInquiryDetails', false);
    localStorage.setItem('backFormInquiryDetailsHinoConnect', false);
    localStorage.setItem('isBackBeforeCallExternal', false);
    localStorage.setItem('backFormInquiryDetailsJustDoIt', false);
    localStorage.setItem('backFormInquiryDetailsJustDoItFollowCall', false);
    localStorage.setItem(
      'backFormInquiryDetailsJustDoItCallApplication',
      false,
    );
    let previousHref = localStorage.getItem('previousHref');
    if (
      checkIsSet(previousHref) &&
      (window.location.pathname == urlHinoSOS.inquiryList ||
        window.location.pathname == urlHinoConnect.inquiryList ||
        window.location.pathname == urlJustDoIt.callList ||
        window.location.pathname == urlJustDoIt.followCall ||
        window.location.pathname == urlJustDoIt.callApplication)
    ) {
      let hastPath = previousHref.split('/');
      let path = '/' + hastPath[1] + '/' + hastPath[2];
      switch (path) {
        case urlHinoSOS.inquiryDetails:
          if (window.location.pathname == urlHinoSOS.inquiryList) {
            localStorage.setItem('backFormInquiryDetails', true);
          }
          if (window.location.pathname == urlJustDoIt.callList) {
            localStorage.setItem('backFormInquiryDetailsJustDoIt', true);
          }
          if (window.location.pathname == urlJustDoIt.followCall) {
            localStorage.setItem(
              'backFormInquiryDetailsJustDoItFollowCall',
              true,
            );
          }
          if (window.location.pathname == urlJustDoIt.callApplication) {
            localStorage.setItem(
              'backFormInquiryDetailsJustDoItCallApplication',
              true,
            );
          }
          break;
        case urlHinoConnect.inquiryDetails:
          localStorage.setItem('backFormInquiryDetailsHinoConnect', true);
          break;
        default:
      }
    }
  };

  let previousHref = localStorage.getItem('previousHref');
  if (checkIsSet(previousHref)) {
    let hastPath = previousHref.split('/');
    let path = '/' + hastPath[1] + '/' + hastPath[2];
    if (
      window.location.pathname == urlOutBoundCall.promoteWarehouseCallList &&
      path == urlOutBoundCall.promoteWarehouseCallDetail
    ) {
      localStorage.setItem('isBackBeforeCallExternal', true);
    }

    if (
      window.location.pathname == urlOutBoundCall.afterCallList &&
      path == urlOutBoundCall.afterCallDetail
    ) {
      localStorage.setItem('isBackAfterCallExternal', true);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
}

export default App;
