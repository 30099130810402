import axios from 'axios';
import store from 'store/config';

import { showNotification } from 'modules/notification/store/actionCreators';
import { signOut } from 'modules/auth/store/actionCreators';
import { translate } from 'utils/intl';

// create new instance
const Http = axios.create();

// set default config
Http.defaults.baseURL = process.env.REACT_APP_API_URL;
Http.defaults.headers.common.Accept = 'application/json';

/**
 * intercept the response so we can handle the
 * expected exceptions from the API
 */
Http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    /**
     * This could be a CORS issue or
     * a dropped internet connection.
     */

    if (
      typeof error.response === 'undefined' &&
      error.message !== 'Request Cancelled'
    ) {
      return alert('A network error occurred.');
    }

    switch (error.response.status) {
      case 401:
        const state = store.getState();

        if (state.auth.isAuthenticated) {
          store.dispatch(signOut());
        }

        break;
      case 422:
        /**
         * Handle Validation Response
         */

        store.dispatch(showNotification('必須項目を入力してください。', false));
        break;
      case 403:
      case 500:
      case 562:
      case 563:
      case 567:
      case 568:
      case 570:
        /**
         * Handle the exceptions when the server
         * responsds with error messages
         */

        if (error.response.data instanceof Blob) {
          store.dispatch(
            showNotification(translate('The file does not exist'), false),
          );
        } else {
          let message = error.response.data.message || error.response.data;
          if (!(message instanceof String)) {
            message = error.response.statusText;
          }
          store.dispatch(showNotification(message, false));
        }

        break;
      default:
        break;
    }

    return Promise.reject(error);
  },
);

export default Http;
