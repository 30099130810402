import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './components/Sidebar';
import Loader from 'modules/loader/components/Loader';
import Notification from 'modules/notification/components/Notification';
import { hideNotification } from 'modules/notification/store/actionCreators';
import { getUser } from 'modules/auth/service';
import * as actions from '../store/actionCreators';
import {
  isExternalAccount,
  checkJustDoItCallApplicationRoute,
} from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: '100%',
  },
}));

const Main = (props) => {
  const { children } = props;
  const loading = useSelector((state) => state.loader);
  const notification = useSelector((state) => state.notification);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const userRole = useSelector(({ auth }) => auth.user && auth.user.roles[0]);

  const [firstLoading, setFirstLoading] = useState(
    useSelector(({ globalState }) => globalState.firstTime),
  );

  useEffect(() => {
    if (!auth.user) {
      dispatch(getUser());
    }
  }, [dispatch, auth.user]);

  useEffect(() => {
    dispatch(actions.setSelectedRow([]));
    dispatch(actions.selectedId([]));
  }, []);

  const openSidebar = useSelector(
    ({ globalState }) => globalState.isSidebarOpen,
  );

  const sidebarIsOpen = localStorage.getItem('openSidebar') === 'true';

  const setOpenSidebar = (open) => {
    setFirstLoading(false);
    if (open) {
      dispatch(actions.openSideBar());
    } else {
      dispatch(actions.closeSideBar());
    }
  };

  let hideSidebar = '';
  let hideTopbar = '';
  if (
    (isDesktop && window.location.pathname === '/') ||
    isExternalAccount(userRole)
  ) {
    hideSidebar = true;
  }

  if (checkJustDoItCallApplicationRoute()) {
    hideTopbar = true;
    hideSidebar = true;
  }

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop && window.location.pathname !== '/',
      })}>
      {loading && <Loader />}

      {auth.user && (
        <React.Fragment>
          <Sidebar
            open={
              hideSidebar === ''
                ? firstLoading
                  ? sidebarIsOpen
                  : openSidebar && !isExternalAccount(userRole)
                : !hideSidebar
            }
            onClose={() => setOpenSidebar(false)}
            variant={isDesktop ? 'persistent' : 'temporary'}
            children={children}
            setOpenSidebar={setOpenSidebar}
            showMenuIcon={
              window.location.pathname !== '/' && !isExternalAccount(userRole)
            }
            isDesktop={isDesktop}
            userRole={userRole}
            hideTopbar={hideTopbar}
          />
          <Notification
            message={notification.message}
            open={notification.show}
            onClose={() => dispatch(hideNotification())}
            success={notification.success}
          />
        </React.Fragment>
      )}
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
