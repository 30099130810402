import * as types from './actionTypes';

const initialState = {
  fetching: false,
  searching: false,
  storing: false,
  deleting: false,
  deleted: false,
  loadingTable: true,
  statusButtonNext: false,
  statusButtonBack: false,
  controlData: {},
  error: {},
  items: [],
  meta: {},
  failed: false,
  officeStaffStatus: {},
  updating: false,
  emailTemplates: [],
  selectedInquiry: {},
  selectedInquiryList: {},
  fileUploads: [],
  externalData: {
    items: [],
    total: 0,
  },
  externalHistoryData: {
    items: [],
    total: 0,
  },
  callCenterMemo: null,
  inquiryDetails: {},
  externalDataCustomer: {
    items: [],
    total: 0,
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.GET_INQUIRY_DETAILS:
      return {
        ...state,
        inquiryDetails: { ...payload },
      };
    case types.FETCH_HINO_CONNECT_CONTROL_DATA_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_HINO_CONNECT_CONTROL_DATA_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        searching: false,
        controlData: { ...payload },
      };
    case types.FETCH_HINO_CONNECT_CONTROL_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };
    case types.FETCH_HINO_CONNECT_PENDING:
      return {
        ...state,
        fetching: true,
        searching: true,
        failed: false,
        loadingTable: true,
      };
    case types.FETCH_HINO_CONNECT_SUCCEEDED:
      const items = payload.data.map((value) => {
        return {
          ...value,
          escalate_destination:
            value.correspondence_details &&
            value.correspondence_details.length > 0 &&
            value.correspondence_details[
              value.correspondence_details.length - 1
            ] &&
            value.correspondence_details[
              value.correspondence_details.length - 1
            ].car_dealer
              ? value.correspondence_details[
                  value.correspondence_details.length - 1
                ].car_dealer.name
              : '',
        };
      });

      return {
        ...state,
        fetching: false,
        searching: false,
        failed: false,
        items: items,
        meta: payload.meta,
      };
    case types.FETCH_HINO_CONNECT_FAILED:
      return {
        ...state,
        fetching: false,
        searching: false,
        failed: true,
        error: payload,
      };

    case types.UPDATE_EMAIL_TEMPLATES_PENDING:
      return {
        ...state,
        updating: true,
        failed: false,
      };
    case types.UPDATE_EMAIL_TEMPLATES_SUCCEEDED: {
      return {
        ...state,
        updating: false,
        failed: false,
        emailTemplates: state.emailTemplates.map((value) =>
          value.id === payload.id ? { ...payload } : value,
        ),
      };
    }
    case types.UPDATE_EMAIL_TEMPLATES_FAILED:
      return {
        ...state,
        failed: true,
        error: payload,
      };
    case types.FETCH_EMAIL_TEMPLATES_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_EMAIL_TEMPLATES_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        emailTemplates: payload,
      };
    case types.FETCH_EMAIL_TEMPLATES_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };
    case types.FETCH_IMPORT_FILE_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_IMPORT_FILE_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        fileUploads: payload,
      };
    case types.POST_IMPORT_FILE_PENDING:
      return {
        ...state,
        fetching: false,
        failed: false,
      };
    case types.POST_IMPORT_FILE_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        fileUploads: {
          ...state.fileUploads,
          [payload.name]: payload.data,
        },
      };
    case types.POST_IMPORT_FILE_FAIL:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };

    case types.DELETE_IMPORT_FILE:
      return {
        ...state,
        deleting: true,
        deleted: false,
      };
    case types.DELETE_IMPORT_FILE_SUCCEEDED:
      let _file = { ...state.fileUploads };
      _file[payload] = null;
      return {
        ...state,
        deleting: false,
        deleted: true,
        fileUploads: _file,
      };
    case types.DELETE_IMPORT_FILE_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: payload,
      };

    //Office Staff Status
    case types.FETCH_OFFICE_STAFF_STATUS_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.FETCH_OFFICE_STAFF_STATUS_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        officeStaffStatus: payload,
      };
    case types.FETCH_OFFICE_STAFF_STATUS_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };
    case types.SET_SELECTED_INQUIRY:
      return {
        ...state,
        selectedInquiry: { ...payload },
      };
    case types.POST_HINO_CONNECT_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.POST_HINO_CONNECT_SUCCEEDED:
      const item = payload.data.correspondence_details.map((value) => {
        return {
          ...payload.data,
          escalate_destination: value.car_dealer ? value.car_dealer.name : '',
        };
      });

      return {
        ...state,
        storing: false,
        failed: false,
        items: [...item, ...state.items],
      };

    case types.POST_HINO_CONNECT_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };
    case types.CLEAR_HINO_CONNECT_FIELDS:
      return {
        ...state,
        fetching: false,
        clear: true,
      };
    case types.UPDATE_HINO_CONNECT_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case types.UPDATE_HINO_CONNECT_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        storing: false,
        failed: false,
        items: state.items.map((value) =>
          value.id === payload.id ? { ...payload } : value,
        ),
      };
    case types.UPDATE_HINO_CONNECT_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };

    //external api
    case types.FETCH_EXTERNAL_DATA_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
        loadingTable: true,
      };

    case types.FETCH_EXTERNAL_DATA_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        storing: false,
        failed: false,
        loadingTable: false,
        externalData: {
          items: payload.data,
          total: payload.total,
        },
      };

    case types.FETCH_EXTERNAL_DATA_CUSTOMER_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        storing: false,
        failed: false,
        externalDataCustomer: {
          items: payload.data,
          total: payload.total,
        },
      };

    case types.FETCH_EXTERNAL_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
        externalData: {
          items: [],
          total: 0,
        },
      };

    //external api history
    case types.FETCH_EXTERNAL_HISTORY_DATA_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.FETCH_EXTERNAL_HISTORY_DATA_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        storing: false,
        failed: false,
        externalHistoryData: {
          items: payload.data,
          total: payload.total,
        },
      };

    case types.FETCH_EXTERNAL_HISTORY_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload.message,
        externalHistoryData: {
          items: [],
          total: 0,
        },
      };

    //DELETE INQUIRY
    case types.DELETE_HINO_INQUIRY_PENDING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      };
    case types.DELETE_HINO_INQUIRY_SUCCEEDED:
      return {
        ...state,
        deleting: false,
        deleted: true,
        items: state.items.filter((obj) => !payload.includes(obj.id)),
      };
    case types.DELETE_HINO_INQUIRY_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        failed: true,
        error: payload,
      };
    case types.FETCH_SELECTED_INQIURY_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        searching: false,
        loadingTable: false,
      };

    //call center memo
    case types.GET_CC_MEMO_PENDING:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.GET_CC_MEMO_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        failed: false,
        callCenterMemo: payload.data,
      };

    case types.GET_CC_MEMO_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        error: payload,
      };

    case types.STATUS_BUTTON_NEXT:
      return {
        ...state,
        statusButtonNext: payload,
      };

    case types.STATUS_BUTTON_BACK:
      return {
        ...state,
        statusButtonBack: payload,
      };
    default:
      return state;
  }
}

export default reducer;
