import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Divider, Drawer, Grid, Typography } from '@material-ui/core';
import { AccountCircle as AccountCircleIcon } from '@material-ui/icons';
import * as actions from '../../store/actionCreators';
import SidebarNav from './SidebarNav';
import { roles } from 'utils/constants';
import { route } from './routes';
import Topbar from './Topbar';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 60,
    height: 60,
  },
  avatarDivider: {
    marginTop: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },

  userName: {
    marginTop: theme.spacing(1),
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  paddingContent: {
    padding: theme.spacing(3),
  },
}));

const Sidebar = (props) => {
  const {
    setOpenSidebar,
    open,
    showMenuIcon,
    children,
    variant,
    onClose,
    isDesktop,
    className,
    userRole,
    hideTopbar,
    ...rest
  } = props;

  const user = useSelector((state) => state.auth.user);
  const classes = useStyles();
  const dispatch = useDispatch();
  const pages = route(userRole, roles, user.permissions);

  useEffect(() => {
    dispatch(actions.setSidebarStatus(open));
  }, [open]);

  const handleDrawerOpen = () => {
    if (open) {
      setOpenSidebar(false);
    } else {
      setOpenSidebar(true);
    }
  };

  return (
    <>
      <Topbar
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        showMenuIcon={showMenuIcon}
        userRole={userRole}
        hideTopbar={hideTopbar}
      />
      <Drawer
        anchor="left"
        classes={{
          paper: classes.drawerPaper,
        }}
        open={open}
        onClose={onClose}
        variant={variant}>
        <div
          {...rest}
          className={clsx(classes.content, classes.paddingContent, {
            [classes.contentShift]: open,
          })}>
          <Grid container direction="column" alignItems="center">
            <Avatar className={classes.avatar}>
              <AccountCircleIcon className={classes.avatar} />
            </Avatar>
            <Typography variant="h4" className={classes.userName}>
              {user.name}
            </Typography>
            {user.roles.map((role) => (
              <Typography variant="body2" key={role}>
                {role}
              </Typography>
            ))}
          </Grid>
          <Divider className={classes.avatarDivider} />
          <SidebarNav
            isDesktop={isDesktop}
            className={classes.nav}
            pages={pages}
            userRole={userRole}
          />
        </div>
      </Drawer>
      <main
        className={clsx({
          [classes.content]: isDesktop,
          [classes.contentShift]: open,
        })}>
        {children}
      </main>
    </>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
