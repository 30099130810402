import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import { useDispatch } from 'react-redux';

import NavItem from './NavItem';
import { translate } from 'utils/intl';
import { signOutUser } from 'modules/auth/service';
import { roles } from '../../../utils/constants';
import { urlSetting } from '../../../utils/routes';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const SidebarNav = (props) => {
  const { isDesktop, pages, className, userRole, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page) => {
        if (
          userRole !== roles.administrator &&
          userRole !== roles.callCenterAgent &&
          page.href === urlSetting.settingInfo
        ) {
          return false;
        }
        return (
          <NavItem
            isDesktop={isDesktop}
            key={page.title}
            page={page}
            classes={classes}
            userRole={userRole}
          />
        );
      })}

      <ListItem className={classes.item} disableGutters>
        <Button
          className={classes.button}
          onClick={() => {
            localStorage.setItem('backFormInquiryDetails', false);
            localStorage.setItem('backFormInquiryDetailsHinoConnect', false);
            localStorage.setItem('backFormInquiryDetailsJustDoIt', false);
            localStorage.setItem(
              'backFormInquiryDetailsJustDoItFollowCall',
              false,
            );
            localStorage.setItem(
              'backFormInquiryDetailsJustDoItCallApplication',
              false,
            );
            dispatch(signOutUser());
          }}>
          <div className={classes.icon}>
            <InputIcon />
          </div>
          {translate('Sign Out')}
        </Button>
      </ListItem>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
