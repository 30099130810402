export const FETCH_USERS_PENDING = 'FETCH_USERS_PENDING';
export const FETCH_USERS_SUCCEEDED = 'FETCH_USERS_SUCCEEDED';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';

export const DELETE_USERS_PENDING = 'DELETE_USERS_PENDING';
export const DELETE_USERS_SUCCEEDED = 'DELETE_USERS_SUCCEEDED';
export const DELETE_USERS_FAILED = 'DELETE_USERS_FAILED';

export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING';
export const UPDATE_USER_SUCCEEDED = 'UPDATE_USER_SUCCEEDED';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const FETCH_USER_STATE_PENDING = 'FETCH_USER_STATE_PENDING';
export const FETCH_USER_STATE_SUCCEEDED = 'FETCH_USER_STATE_SUCCEEDED';
export const FETCH_USER_STATE_FAILED = 'FETCH_USER_STATE_FAILED';

export const FETCH_USER_CONTROL_DATA_PENDING =
  'FETCH_USER_CONTROL_DATA_PENDING';
export const FETCH_USER_CONTROL_DATA_SUCCEEDED =
  'FETCH_USER_CONTROL_DATA_SUCCEEDED';
export const FETCH_USER_CONTROL_DATA_FAILED = 'FETCH_USER_CONTROL_DATA_FAILED';

export const POST_USER_PENDING = 'POST_USER_PENDING';
export const POST_USER_SUCCEEDED = 'POST_USER_SUCCEEDED';
export const POST_USER_FAILED = 'POST_USER_FAILED';

export const CLEAR_USER_ERRORS = 'CLEAR_USER_ERRORS';
export const FAIL_CSV_DOWNLOAD = 'FAIL_CSV_DOWNLOAD';
