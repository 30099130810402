export const FETCH_CAR_DEALER_DATA_PENDING = 'FETCH_CAR_DEALER_DATA_PENDING';
export const FETCH_CAR_DEALER_DATA_SUCCEEDED =
  'FETCH_CAR_DEALER_DATA_SUCCEEDED';
export const FETCH_CAR_DEALER_DATA_FAILED = 'FETCH_CAR_DEALER_DATA_FAILED';
export const FETCH_MASTER_CATEGORIES_DATA_PENDING =
  'FETCH_MASTER_CATEGORIES_DATA_PENDING';
export const FETCH_MASTER_CATEGORIES_DATA_SUCCEEDED =
  'FETCH_MASTER_CATEGORIES_DATA_SUCCEEDED';
export const FETCH_MASTER_CATEGORIES_DATA_FAILED =
  'FETCH_MASTER_CATEGORIES_DATA_FAILED';

export const GET_ALL_MASTER_CATEGORIES_DATA_PENDING =
  'GET_ALL_MASTER_CATEGORIES_DATA_PENDING';
export const GET_ALL_MASTER_CATEGORIES_DATA_SUCCEEDED =
  'GET_ALL_MASTER_CATEGORIES_DATA_SUCCEEDED';
export const GET_ALL_MASTER_CATEGORIES_DATA_FAILED =
  'GET_ALL_MASTER_CATEGORIES_DATA_FAILED';

export const UPDATE_MASTER_CATEGORY_DATA_PENDING =
  'UPDATE_MASTER_CATEGORY_DATA_PENDING';
export const UPDATE_MASTER_CATEGORY_DATA_SUCCEEDED =
  'UPDATE_MASTER_CATEGORY_DATA_SUCCEEDED';
export const UPDATE_MASTER_CATEGORY_DATA_FAILED =
  'UPDATE_MASTER_CATEGORY_DATA_FAILED';
export const FETCH_MASTER_OPTION_DATA_PENDING =
  'FETCH_MASTER_OPTION_DATA_PENDING';
export const FETCH_MASTER_OPTION_DATA_SUCCEEDED =
  'FETCH_MASTER_OPTION_DATA_SUCCEEDED';
export const FETCH_MASTER_OPTION_DATA_FAILED =
  'FETCH_MASTER_OPTION_DATA_FAILED';
export const CREATE_MASTER_OPTION_DATA_PENDING =
  'CREATE_MASTER_OPTION_DATA_PENDING';
export const CREATE_MASTER_OPTION_DATA_SUCCEEDED =
  'CREATE_MASTER_OPTION_DATA_SUCCEEDED';
export const CREATE_MASTER_OPTION_DATA_FAILED =
  'CREATE_MASTER_OPTION_DATA_FAILED';
export const UPDATE_MASTER_OPTION_DATA_PENDING =
  'UPDATE_MASTER_OPTION_DATA_PENDING';
export const UPDATE_MASTER_OPTION_DATA_SUCCEEDED =
  'UPDATE_MASTER_OPTION_DATA_SUCCEEDED';
export const UPDATE_MASTER_OPTION_DATA_FAILED =
  'UPDATE_MASTER_OPTION_DATA_FAILED';
export const DELETE_MASTER_OPTION_DATA_PENDING =
  'DELETE_MASTER_OPTION_DATA_PENDING';
export const DELETE_MASTER_OPTION_DATA_SUCCEEDED =
  'DELETE_MASTER_OPTION_DATA_SUCCEEDED';
export const DELETE_MASTER_OPTION_DATA_FAILED =
  'DELETE_MASTER_OPTION_DATA_FAILED';
export const IMPORT_CUSTOMER_DATE_PENDING = 'IMPORT_CUSTOMER_DATE_PENDING';
export const IMPORT_CUSTOMER_DATE_SUCCEEDED = 'IMPORT_CUSTOMER_DATE_SUCCEEDED';
export const IMPORT_CUSTOMER_DATE_FAILED = 'IMPORT_CUSTOMER_DATE_FAILED';
export const FETCH_DM_SHIPPING_DATA_PENDING = 'FETCH_DM_SHIPPING_DATA_PENDING';
export const FETCH_DM_SHIPPING_DATA_SUCCEEDED =
  'FETCH_DM_SHIPPING_DATA_SUCCEEDED';
export const FETCH_DM_SHIPPING_DATA_FAILED = 'FETCH_DM_SHIPPING_DATA_FAILED';
export const UPDATE_DM_SHIPPING_DATA_PENDING =
  'UPDATE_DM_SHIPPING_DATA_PENDING';
export const UPDATE_DM_SHIPPING_DATA_SUCCEEDED =
  'UPDATE_DM_SHIPPING_DATA_SUCCEEDED';
export const UPDATE_DM_SHIPPING_DATA_FAILED = 'UPDATE_DM_SHIPPING_DATA_FAILED';
export const UPDATE_CONTENT_VALIDATION_DATA_FAILED = 'UPDATE_CONTENT_VALIDATION_DATA_FAILED';
export const UPDATE_CONTENT_VALIDATION_DATA_PENDING = 'UPDATE_CONTENT_VALIDATION_DATA_PENDING';
export const UPDATE_CONTENT_VALIDATION_DATA_SUCCEEDED = 'UPDATE_CONTENT_VALIDATION_DATA_SUCCEEDED';
export const OUTBOUND_VEHICLE_PENDING = 'OUTBOUND_VEHICLE_PENDING';
export const OUTBOUND_VEHICLE_SUCCEEDED = 'OUTBOUND_VEHICLE_SUCCEEDED';
export const OUTBOUND_VEHICLE_FAILED = 'OUTBOUND_VEHICLE_FAILED';
export const OUTBOUND_CALL_REQUIRED_PENDING = 'OUTBOUND_CALL_REQUIRED_PENDING';
export const OUTBOUND_CALL_REQUIRED_SUCCEEDED = 'OUTBOUND_CALL_REQUIRED_SUCCEEDED';
export const OUTBOUND_CALL_REQUIRED_FAILED = 'OUTBOUND_CALL_REQUIRED_FAILED';
export const OUTGOING_CALL_CUSTOMER_COMPANY_DEALER_PENDING =
  'OUTGOING_CALL_CUSTOMER_COMPANY_DEALER_PENDING';
export const OUTGOING_CALL_CUSTOMER_COMPANY_DEALER_SUCCEEDED =
  'OUTGOING_CALL_CUSTOMER_COMPANY_DEALER_SUCCEEDED';
export const OUTGOING_CALL_CUSTOMER_COMPANY_DEALER_FAILED =
  'OUTGOING_CALL_CUSTOMER_COMPANY_DEALER_FAILED';
export const OUTGOING_CALL_DETAIL_PENDING = 'OUTGOING_CALL_DETAIL_PENDING';
export const OUTGOING_CALL_DETAIL_SUCCEEDED = 'OUTGOING_CALL_DETAIL_SUCCEEDED';
export const OUTGOING_CALL_DETAIL_FAILED = 'OUTGOING_CALL_DETAIL_FAILED';
export const UPDATE_OUTBOUND_INFORMATION_PENDING =
  'UPDATE_OUTBOUND_INFORMATION_PENDING';
export const UPDATE_OUTBOUND_INFORMATION_SUCCEEDED =
  'UPDATE_OUTBOUND_INFORMATION_SUCCEEDED';
export const UPDATE_OUTBOUND_INFORMATION_FAILED =
  'UPDATE_OUTBOUND_INFORMATION_FAILED';
export const COUNTER_OUTBOUND_LIST_PENDING = 'COUNTER_OUTBOUND_LIST_PENDING';
export const COUNTER_OUTBOUND_LIST_SUCCESSFUL =
  'COUNTER_OUTBOUND_LIST_SUCCESSFUL';
export const COUNTER_OUTBOUND_LIST_FAILED = 'COUNTER_OUTBOUND_LIST_FAILED';
export const COUNTER_OUTBOUND_STATUS_SUCCESS =
  'COUNTER_OUTBOUND_STATUS_SUCCESS';
export const COUNTER_OUTBOUND_STATUS_FAILED = 'COUNTER_OUTBOUND_STATUS_FAILED';
export const COUNTER_OUTBOUND_STATUS_PENDING =
  'COUNTER_OUTBOUND_STATUS_PENDING';
export const OUTGOING_AFTER_CALL_CUSTOMER_COMPANY_DEALER_PENDING =
  'OUTGOING_AFTER_CALL_CUSTOMER_COMPANY_DEALER_PENDING';
export const OUTGOING_AFTER_CALL_CUSTOMER_COMPANY_DEALER_SUCCEEDED =
  'OUTGOING_AFTER_CALL_CUSTOMER_COMPANY_DEALER_SUCCEEDED';
export const OUTGOING_AFTER_CALL_CUSTOMER_COMPANY_DEALER_FAILED =
  'OUTGOING_AFTER_CALL_CUSTOMER_COMPANY_DEALER_FAILED';
export const OUTGOING_AFTER_CALL_DETAIL_PENDING =
  'OUTGOING_AFTER_CALL_DETAIL_PENDING';
export const OUTGOING_AFTER_CALL_DETAIL_SUCCEEDED =
  'OUTGOING_AFTER_CALL_DETAIL_SUCCEEDED';
export const OUTGOING_AFTER_CALL_DETAIL_FAILED =
  'OUTGOING_AFTER_CALL_DETAIL_FAILED';
export const OUTGOING_AFTER_CALL_HISTORY_DETAIL_PENDING =
  'OUTGOING_AFTER_CALL_HISTORY_DETAIL_PENDING';
export const OUTGOING_AFTER_CALL_HISTORY_DETAIL_SUCCEEDED =
  'OUTGOING_AFTER_CALL_HISTORY_DETAIL_SUCCEEDED';
export const OUTGOING_AFTER_CALL_HISTORY_DETAIL_FAILED =
  'OUTGOING_AFTER_CALL_HISTORY_DETAIL_FAILED';

export const FETCH_CODE_NAME_CAR_DEALER_DATA_PENDING =
  'FETCH_CODE_NAME_CAR_DEALER_DATA_PENDING';
export const FETCH_CODE_NAME_CAR_DEALER_DATA_SUCCEEDED =
  'FETCH_CODE_NAME_CAR_DEALER_DATA_SUCCEEDED';
export const FETCH_CODE_NAME_CAR_DEALER_DATA_FAILED =
  'FETCH_CODE_NAME_CAR_DEALER_DATA_FAILED';

export const EXPORT_CUSTOMER_DATE_PENDING = 'EXPORT_CUSTOMER_DATE_PENDING';
export const EXPORT_CUSTOMER_DATE_SUCCEEDED = 'EXPORT_CUSTOMER_DATE_SUCCEEDED';
export const EXPORT_CUSTOMER_DATE_FAILED = 'EXPORT_CUSTOMER_DATE_FAILED';

export const GET_DATA_COUNT_INTENT_IMPORT_REPORT_TABLE_PENDING =
  'GET_DATA_COUNT_INTENT_IMPORT_REPORT_TABLE_PENDING';
export const GET_DATA_COUNT_INTENT_IMPORT_REPORT_TABLE_SUCCEEDED =
  'GET_DATA_COUNT_INTENT_IMPORT_REPORT_TABLE_SUCCEEDED';
export const GET_DATA_COUNT_INTENT_IMPORT_REPORT_TABLE_FAILED =
  'GET_DATA_COUNT_INTENT_IMPORT_REPORT_TABLE_FAILED';

export const GET_DATA_COUNT_CALL_STATUS_REPORT_TABLE_PENDING =
  'GET_DATA_COUNT_CALL_STATUS_REPORT_TABLE_PENDING';
export const GET_DATA_COUNT_CALL_STATUS_REPORT_TABLE_SUCCEEDED =
  'GET_DATA_COUNT_CALL_STATUS_REPORT_TABLE_SUCCEEDED';
export const GET_DATA_COUNT_CALL_STATUS_REPORT_TABLE_FAILED =
  'GET_DATA_COUNT_CALL_STATUS_REPORT_TABLE_FAILED';

export const GET_INFORMATION_REPORT_DETAIL_TABLE_PENDING =
  'GET_INFORMATION_REPORT_DETAIL_TABLE_PENDING';
export const GET_INFORMATION_REPORT_DETAIL_TABLE_SUCCEEDED =
  'GET_INFORMATION_REPORT_DETAIL_TABLE_SUCCEEDED';
export const GET_INFORMATION_REPORT_DETAIL_TABLE_FAILED =
  'GET_INFORMATION_REPORT_DETAIL_TABLE_FAILED';
export const UPDATE_CALL_AFTER_DATA_PENDING = 'UPDATE_CALL_AFTER_DATA_PENDING';
export const UPDATE_AFTER_CALL_DATA_SUCCEEDED =
  'UPDATE_AFTER_CALL_DATA_SUCCEEDED';
export const UPDATE_AFTER_CALL_DATA_FAILED = 'UPDATE_AFTER_CALL_DATA_FAILED';
export const UPDATE_AFTER_CALL_HISTORY_DATA_PENDING =
  'UPDATE_AFTER_CALL_HISTORY_DATA_PENDING';
export const UPDATE_AFTER_CALL_HISTORY_DATA_SUCCEEDED =
  'UPDATE_AFTER_CALL_HISTORY_DATA_SUCCEEDED';
export const UPDATE_AFTER_CAL_HISTORY_DATA_FAILED =
  'UPDATE_AFTER_CAL_HISTORY_DATA_FAILED';

export const FETCH_DESIRE_IMPROVE_SERVICE_PENDING =
  'FETCH_DESIRE_IMPROVE_SERVICE_PENDING';
export const FETCH_DESIRE_IMPROVE_SERVICE_SUCCESS =
  'FETCH_DESIRE_IMPROVE_SERVICE_SUCCESS';
export const FETCH_DESIRE_IMPROVE_SERVICE_FAILED =
  'FETCH_DESIRE_IMPROVE_SERVICE_FAILED';

export const FETCH_CALL_AFTER_SERVICE_SUCCESS =
  'FETCH_CALL_AFTER_SERVICE_SUCCESS';
export const FETCH_CALL_AFTER_SERVICE_FAIL = 'FETCH_CALL_AFTER_SERVICE_FAIL';
export const FETCH_CALL_AFTER_SERVICE_PENDING =
  'FETCH_CALL_AFTER_SERVICE_PENDING';

export const FETCH_OUTBOUND_CONTROL_DATA_PENDING =
  'FETCH_OUTBOUND_CONTROL_DATA_PENDING';
export const FETCH_OUTBOUND_CONTROL_DATA_SUCCEEDED =
  'FETCH_OUTBOUND_CONTROL_DATA_SUCCEEDED';
export const FETCH_OUTBOUND_CONTROL_DATA_FAILED =
  'FETCH_OUTBOUND_CONTROL_DATA_FAILED';

export const FETCH_OUTBOUND_NOTIFICATION_PENDING =
  'FETCH_OUTBOUND_NOTIFICATION_PENDING';
export const FETCH_OUTBOUND_NOTIFICATION_SUCCEEDED =
  'FETCH_OUTBOUND_NOTIFICATION_SUCCEEDED';
export const FETCH_OUTBOUND_NOTIFICATION_FAILED =
  'FETCH_OUTBOUND_NOTIFICATION_FAILED';

export const FETCH_OUTBOUND_USER_TYPE_PENDING =
  'FETCH_OUTBOUND_USER_TYPE_PENDING';
export const FETCH_OUTBOUND_USER_TYPE_SUCCESS =
  'FETCH_OUTBOUND_USER_TYPE_SUCCESS';
export const FETCH_OUTBOUND_USER_TYPE_FAILED =
  'FETCH_OUTBOUND_USER_TYPE_FAILED';

export const FETCH_SERVICE_SATISFACTION_SUCCESS =
  'FETCH_SERVICE_SATISFACTION_SUCCESS';
export const FETCH_SERVICE_SATISFACTION_FAIL =
  'FETCH_SERVICE_SATISFACTION_FAIL';
export const FETCH_SERVICE_SATISFACTION_PENDING =
  'FETCH_SERVICE_SATISFACTION_PENDING';
export const HANDLE_CREATE_MESSAGE_SUCCESS = 'HANDLE_CREATE_MESSAGE_SUCCESS';

export const FETCH_CONTRACT_STAFF_SUCCESS = 'FETCH_CONTRACT_STAFF_SUCCESS';
export const FETCH_CONTRACT_STAFF_FAIL = 'FETCH_CONTRACT_STAFF_FAIL';
export const FETCH_CONTRACT_STAFF_PENDING = 'FETCH_CONTRACT_STAFF_PENDING';

export const HANDLE_RELOAD_SALES_COMPANY_TABLE = 'HANDLE_RELOAD_SALES_COMPANY_TABLE';

export const UPDATE_COMPANY_MASTER_VALIDATE_MESSAGE = 'UPDATE_COMPANY_MASTER_VALIDATE_MESSAGE';

export const FETCH_WAREHOUSING_STATUS_REPORT_SUCCESS = 'FETCH_WAREHOUSING_STATUS_REPORT_SUCCESS';
export const FETCH_WAREHOUSING_STATUS_REPORT_FAIL = 'FETCH_WAREHOUSING_STATUS_REPORT_FAIL';
export const FETCH_WAREHOUSING_STATUS_REPORT_PENDING = 'FETCH_WAREHOUSING_STATUS_REPORT_PENDING';

export const COUNTER_HISTORY_OUTBOUND_STATUS_EXTERNAL_PENDING = 'COUNTER_HISTORY_OUTBOUND_STATUS_EXTERNAL_PENDING';
export const COUNTER_HISTORY_OUTBOUND_STATUS_EXTERNAL_SUCCESS = 'COUNTER_HISTORY_OUTBOUND_STATUS_EXTERNAL_SUCCESS';
export const COUNTER_HISTORY_OUTBOUND_STATUS_EXTERNAL_FAILED = 'COUNTER_HISTORY_OUTBOUND_STATUS_EXTERNAL_FAILED';

export const UPDATE_OUTBOUND_DEALER_PENDING = 'UPDATE_OUTBOUND_DEALER_PENDING';
export const UPDATE_OUTBOUND_DEALER_SUCCEEDED = 'UPDATE_OUTBOUND_DEALER_SUCCEEDED';
export const UPDATE_OUTBOUND_DEALER_FAILED = 'UPDATE_OUTBOUND_DEALER_FAILED';

export const COUNTER_AFTER_OUTBOUND_STATUS_EXTERNAL_PENDING = 'COUNTER_AFTER_OUTBOUND_STATUS_EXTERNAL_PENDING';
export const COUNTER_AFTER_OUTBOUND_STATUS_EXTERNAL_SUCCESS = 'COUNTER_AFTER_OUTBOUND_STATUS_EXTERNAL_SUCCESS';
export const COUNTER_AFTER_OUTBOUND_STATUS_EXTERNAL_FAILED = 'COUNTER_AFTER_OUTBOUND_STATUS_EXTERNAL_FAILED';

export const UPDATE_OUTBOUND_WORK_REPORT_PENDING = 'UPDATE_OUTBOUND_WORK_REPORT_PENDING';
export const UPDATE_OUTBOUND_WORK_REPORT_SUCCEEDED = 'UPDATE_OUTBOUND_WORK_REPORT_SUCCEEDED';
export const UPDATE_OUTBOUND_WORK_REPORT_FAILED = 'UPDATE_OUTBOUND_WORK_REPORT_FAILED';