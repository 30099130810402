import * as types from './actionTypes';

const initialState = {
  fetching: false,
  updating: false,
};

function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.UPDATE_HITOSS_URL_PENDING:
      return {
        ...state,
        updating: true,
      };
    case types.UPDATE_HITOSS_URL_SUCCEEDED:
      return {
        ...state,
        updating: false,
      };
    case types.UPDATE_HITOSS_URL_FAILED:
      return {
        ...state,
        updating: false,
      };
    case types.HITOSS_URL_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case types.HITOSS_URL_FETCHED:
      return {
        ...state,
        fetching: false,
      };

    case types.UPDATE_EXPRESSWAY_PENDING:
      return {
        ...state,
        updating: true,
      };
    case types.UPDATE_EXPRESSWAY_SUCCEEDED:
      return {
        ...state,
        updating: false,
      };
    case types.UPDATE_EXPRESSWAY_FAILED:
      return {
        ...state,
        updating: false,
      };
    default:
      return state;
  }
}

export default reducer;
